import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ContactsAppointments from './ContactsAppointments';
import ContactsAppointmentsAdd from './ContactsAppointmentsAdd';
import Button from '../../../components/CustomButtons/Button';
import {Add} from '@material-ui/icons';
import {Tabs, Tab} from '@material-ui/core';
import {getAppointments} from '../../../store/actions/crm_appointments';

const ContactsFormAppointments = ({contactData, action, getAppointments, appointmentsForAdd, setAppointmentsForAdd, getContacts, contactsData, canEdit}) => {
	const [createModal, setCreateModal] = useState(false);
	const [upcomingState, setUpcomingState] = useState(true);
	
	const contactName = `${contactData.first_name ? contactData.first_name.trim() : ''} ${contactData.last_name ? contactData.last_name.trim() : ''}`;
	// let contactName = "";
	const contactId = contactData.id;
	const currentDate = new Date().getDate();
	const pastYear = new Date().setDate(currentDate - 365);
	const timeParams = {
		timeMin: upcomingState ? new Date().toISOString() : new Date(pastYear).toISOString(),
		timeMax: upcomingState ? null : new Date().toISOString()
	};

	useEffect(() => {
		getAppointments(timeParams);
	}, [upcomingState]);

	return (
		<div className='illi-table'>
			<div style={{display: 'flex'}}>
				{(action !== 'Add' && canEdit) && <Button
					color="primary"
					onClick={() => {
						setCreateModal(true);
					}}
				>
					<Add /> New Appointment
				</Button>}
				{action !== 'Add' && <Tabs 
					TabIndicatorProps={{style: 
						{background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f'
					}}} 
					style={{marginLeft: '1.3rem'}} 
					value={upcomingState ? 0 : 1}>
					<Tab
						onClick={() => {
							setUpcomingState(true);
						}}
						tabIndex={0}
						label='Upcoming'
					/>
					<Tab
						tabIndex={1}
						label='Past'
						onClick={() => {
							setUpcomingState(false);
						}}
					/>
				</Tabs>}
			</div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					{action !== 'Add' && <ContactsAppointments 
						edit={action === 'Edit'} 
						createModal={createModal} 
						setCreateModal={setCreateModal} 
						contactId={contactId} 
						contactName={contactName} 
						upcomingState={upcomingState} 
						setUpcomingState={setUpcomingState}
						getContacts={getContacts}
						contactsData={contactsData}
						getAppointments={getAppointments}
						canEdit={canEdit}
					/>}
					{action === 'Add' && <ContactsAppointmentsAdd 
						appointmentsForAdd={appointmentsForAdd}
						setAppointmentsForAdd={setAppointmentsForAdd}
						contactName={contactName}
						getContacts={getContacts}
						contactsData={contactsData}
						getAppointments={getAppointments}
						canEdit={canEdit}
					/>}
				</GridItem>
			</GridContainer>
		</div>
	);
};
export default connect(null, {getAppointments})(ContactsFormAppointments);
