import {put} from 'redux-saga/effects';
import axios from '../axios/axios-crm';
import * as actions from '../actions/crm_appointments';

export function* getAppointmentsSaga(action) {
	yield put(actions.getAppointmentsStart());
	const currentDate = new Date().getDate();
	const yesterday = new Date().setDate(currentDate - 1);

	try {
		let url = `/appointments?timeMin=${action.params.timeMin}${action.params.timeMax ? `&timeMax=${new Date(yesterday).toISOString()}` : ''}`;
		if (action.params) {
			const fields = Object.keys(action.params);
			for (let i = 0; i < fields.length; i++) {
				const f = fields[i];
				if (action.params[f]) url += `&${f}=${action.params[f]}`;
			}
		}
		const response = yield axios.get(url);
		yield put(actions.getAppointmentsSuccess(response.data));
	} catch (err) {
		yield put(actions.getAppointmentsFail(err));
	}
}

export function* getAppointmentSaga(action) {
	yield put(actions.getAppointmentStart());
	try {
		let url = `/appointment/${action.id}`;
		const response = yield axios.get(url);
		yield put(actions.getAppointmentSuccess(response.data));
	} catch (err) {
		yield put(actions.getAppointmentFail(err));
	}
}

export function* createAppointmentSaga(action) {
	const {summary, description, start, end, id, name, invitees} = action.appointmentData;
	const config = {
		headers: {
			'Conent-Type': 'application/json'
		}
	};


	const formattedData = {
		summary,
		description: `${id} --- ${name} --- ${description} --- (Appointment)`,
		dateTimeStart: new Date(start).toISOString(),
		timeZoneStart: Intl.DateTimeFormat().resolvedOptions().timeZone,
		dateTimeEnd: new Date(end).toISOString(),
		timeZoneEnd: Intl.DateTimeFormat().resolvedOptions().timeZone,
		colorId: '1',
		creator: {
			displayName: 'Admin'
		},
		invitees
	};

	yield put(actions.createAppointmentStart());
	try {
		const response = yield axios.post(`/appointment`, formattedData, config);
		
		yield put(actions.createAppointmentSuccess(response.data));
	} catch (err) {
		if (err.response) {
			if (err.response.data) {
				
				return yield put(actions.createAppointmentFail(err.response.data));
			}
		}
		return yield put(actions.createAppointmentFail(`Failed to create appointment`));
	}
}

export function* updateAppointmentSaga(action) {
	yield put(actions.updateAppointmentStart());
	const {summary, description, start, end, id, name, eventId, invitees} = action.appointmentData;
	const config = {
		headers: {
			'Conent-Type': 'application/json'
		}
	};

	const formattedData = {
		summary,
		description: `${id} --- ${name} --- ${description} --- (Appointment)`,
		dateTimeStart: new Date(start).toISOString(),
		timeZoneStart: Intl.DateTimeFormat().resolvedOptions().timeZone,
		dateTimeEnd: new Date(end).toISOString(),
		timeZoneEnd: Intl.DateTimeFormat().resolvedOptions().timeZone,
		eventId,
		invitees
	};

	try {
		const response = yield axios.put(`/appointment`, formattedData, config);
		
		yield put(actions.updateAppointmentSuccess(response.data));
	} catch (err) {
		if (err.response) {
			if (err.response.data) {
				
				return yield put(actions.updateAppointmentFail(err.response.data));
			}
		}
		return yield put(actions.updateAppointmentFail(`Failed to update appointment`));
	}
}

export function* deleteAppointmentSaga(action) {
	yield put(actions.deleteAppointmentStart());
	try {
		const response = yield axios.delete(`/appointment/${action.eventId}`);
		
		yield put(actions.deleteAppointmentSuccess(response.data));
	} catch (err) {
		if (err.response) {
			if (err.response.data) {
				
				return yield put(actions.deleteAppointmentFail(err.response.data));
			}
		}
		return yield put(actions.deleteAppointmentFail(`Failed to update appointment`));
	}
}
