import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ShowingList from "./ShowingList";
import ShowingForm from "./ShowingForm";

import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';

const Showing = (props) => {
    const [tab, setTab] = useState('List');
    const [showingData, setShowingData] = useState(null);
    const [msgsForAdd, setMsgsForAdd] = useState({error:null,success:null});
    const [openedAsk, setOpenedAsk] = useState(false);

    const { showingsData, dataObj, getShowings, canEdit, canDelete,
        createShowing, updateShowing, deleteShowing, error, success,
        propertyAction, setShowingsForAdd, showingsForAdd, getContacts,
        contactsData } = props;

    

    const addForAdd = (data) => {
        setMsgsForAdd({error:null,success:null});
        const newData = { ...data, id:showingsForAdd.length };
        const newList = [ ...showingsForAdd, newData ];
        
        setShowingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Added Offer'});
    }
    const updateForAdd = (data) => {
        setMsgsForAdd({error:null,success:null});
        const newList = [ ...showingsForAdd ].map((l,i) => {
            if (i === data.id) return data;
            return l;
        })
        
        setShowingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Updated Showing'});
    }
    const deleteForAdd = (index) => {
        setMsgsForAdd({error:null,success:null});
        const newList = [ ...showingsForAdd ].filter((f,i) => {
            if (i === index) return false;
            return true;
        })
        setShowingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Deleted Showing'});
    }

    useEffect(() => {
        if (dataObj) {
            if (dataObj.id) {
                getShowings(dataObj.id);
            }
        }
    }, [])

    const showForm = (tab === 'View' || tab === 'Add' || tab === 'Edit') ? true : false;

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {/* <Button
                    color={(tab === 'List' || tab === 'Edit' || tab === 'View') ? 'success' : 'primary'}
                    onClick={() => {
                        getShowings(dataObj.id);
                        setTab('List');
                    }}
                    style={{marginRight:10}}
                >
                    View / Edit Showings
                </Button> */}
                {props.propertyAction !== 'View' && <Button
                    color={tab === 'Add' ? 'success' : 'primary'}
                    onClick={() => {
                        setTab('Add');
                    }}
                >
                    <i className="fa fa-plus"></i> Add Showing
                </Button>}
                <hr />
                {tab === 'List' && <ShowingList
                    canEdit={propertyAction !== 'Add' ? canEdit : true}
                    showingsData={propertyAction !== 'Add' ? showingsData : showingsForAdd}
                    showingData={showingData}
                    setShowingData={setShowingData}
                    setTab={setTab}
                />}
                {showForm &&
            <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
            <div className="dialog-wrapper">
                <ShowingForm
                    showingData={showingData}
                    action={tab}
                    setTab={setTab}
                    canEdit={propertyAction !== 'Add' ? canEdit : true}
                    canDelete={propertyAction !== 'Add' ? canDelete : true}
                    setMsgsForAdd={setMsgsForAdd}

                    updateShowing={propertyAction !== 'Add' ? updateShowing : updateForAdd}
                    createShowing={propertyAction !== 'Add' ? createShowing : addForAdd}
                    deleteShowing={propertyAction !== 'Add' ? deleteShowing : deleteForAdd}
                    getShowings={() => {
                        if (propertyAction !== 'Add') {
                            getShowings(dataObj.id);
                        }
                    }}
                    getContacts={getContacts}
                    contactsData={contactsData}

                    propertyID={dataObj.id}
                    error={propertyAction !== 'Add' ? error : msgsForAdd.error}
                    success={propertyAction !== 'Add' ? success : msgsForAdd.success}
                    contactLoading={props.contactLoading}

                    setOpenedAsk={setOpenedAsk}
                    showCloseBtn={true}

                    propertyAction={propertyAction}
                />
                </div>
            </Dialog>
            }
            </GridItem>

            {openedAsk && <CustomDialogClose
                        ok_go={() => {
                            setTab('List');
                        }}
                        open={openedAsk}
                        close={() => {
                            setOpenedAsk(false);
                        }}
                    />}
        </GridContainer>
    )
}

const mapStateToProps = state => {
    const p = state.crm_showings;
    const c = state.crm_contacts;
    return {
        error: p.error,
        success: p.success,
        showingsData: p.showingsData,
        showingData: p.showingData,
        loading: p.loading,
        contactsData: c.contactsData,
        contactLoading: c.loading,
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      getShowings: (property_id) => {
          dispatch(actions.getShowings(property_id));
      },
      getShowing: (id) => {
          dispatch(actions.getShowing(id));
      },
      createShowing: (data) => {
          dispatch(actions.createShowing(data));
      },
      updateShowing: (data) => {
          dispatch(actions.updateShowing(data));
      },
      deleteShowing: (id) => {
          dispatch(actions.deleteShowing(id));
      },
      getContacts: (data) => {
          dispatch(actions.getContacts(data));
      },
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Showing);
