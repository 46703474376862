import * as actionTypes from './actionTypes';

// GET ALL APPOINTMENTS

export const getAppointments = (params) => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENTS,
		params
	};
};

export const getAppointmentsStart = () => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENTS_START
	};
};

export const getAppointmentsSuccess = (response) => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENTS_SUCCESS,
		response
	};
};

export const getAppointmentsFail = (err) => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENTS_FAIL,
		err
	};
};

// GET APPOINTMENT BY EVENT ID

export const getAppointment = (id) => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENT,
		id
	};
};

export const getAppointmentStart = () => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENT_START
	};
};

export const getAppointmentSuccess = (response) => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENT_SUCCESS,
		response
	};
};

export const getAppointmentFail = (err) => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENT_FAIL,
		err
	};
};

export const getAppointmentClear = () => {
	return {
		type: actionTypes.GET_CALENDAR_APPOINTMENT_CLEAR
	};
};

// CREATE APPOINTMENT

export const createAppointment = (appointmentData) => {
	return {
		type: actionTypes.CREATE_CALENDAR_APPOINTMENT,
		appointmentData
	};
};

export const createAppointmentStart = () => {
	return {
		type: actionTypes.CREATE_CALENDAR_APPOINTMENT_START
	};
};

export const createAppointmentSuccess = (response) => {
	return {
		type: actionTypes.CREATE_CALENDAR_APPOINTMENT_SUCCESS,
		response
	};
};

export const createAppointmentFail = (err) => {
	return {
		type: actionTypes.CREATE_CALENDAR_APPOINTMENT_FAIL,
		err
	};
};

// UPDATE APPOINTMENT

export const updateAppointment = (appointmentData) => {
	return {
		type: actionTypes.UPDATE_CALENDAR_APPOINTMENT,
		appointmentData
	};
};

export const updateAppointmentStart = () => {
	return {
		type: actionTypes.UPDATE_CALENDAR_APPOINTMENT_START
	};
};

export const updateAppointmentSuccess = (response) => {
	return {
		type: actionTypes.UPDATE_CALENDAR_APPOINTMENT_SUCCESS,
		response
	};
};

export const updateAppointmentFail = (err) => {
	return {
		type: actionTypes.UPDATE_CALENDAR_APPOINTMENT_FAIL,
		err
	};
};

// DELETE APPOINTMENT

export const deleteAppointment = (eventId) => {
	return {
		type: actionTypes.DELETE_CALENDAR_APPOINTMENT,
		eventId
	};
};

export const deleteAppointmentStart = () => {
	return {
		type: actionTypes.DELETE_CALENDAR_APPOINTMENT_START
	};
};

export const deleteAppointmentSuccess = (response) => {
	return {
		type: actionTypes.DELETE_CALENDAR_APPOINTMENT_SUCCESS,
		response
	};
};

export const deleteAppointmentFail = (err) => {
	return {
		type: actionTypes.DELETE_CALENDAR_APPOINTMENT_FAIL,
		err
	};
};
