import Dashboard from "views/Dashboard/Dashboard.jsx";
import Buttons from "views/Components/Buttons.jsx";
import GridSystem from "views/Components/GridSystem.jsx";
import Panels from "views/Components/Panels.jsx";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SweetAlert from "views/Components/SweetAlert.jsx";
import Notifications from "views/Components/Notifications.jsx";
import Icons from "views/Components/Icons.jsx";
import Typography from "views/Components/Typography.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import ExtendedForms from "views/Forms/ExtendedForms.jsx";
import ValidationForms from "views/Forms/ValidationForms.jsx";
import Wizard from "views/Forms/Wizard.jsx";
import RegularTables from "views/Tables/RegularTables.jsx";
import ExtendedTables from "views/Tables/ExtendedTables.jsx";
import ReactTables from "views/Tables/ReactTables.jsx";
import GoogleMaps from "views/Maps/GoogleMaps.jsx";
import FullScreenMap from "views/Maps/FullScreenMap.jsx";
import VectorMap from "views/Maps/VectorMap.jsx";
import Charts from "views/Charts/Charts.jsx";
import Calendar from "views/Calendar/Calendar.jsx";
import Widgets from "views/Widgets/Widgets.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";
import TimelinePage from "views/Pages/Timeline.jsx";
import RTLSupport from "views/Pages/RTLSupport.jsx";
import PortalPermissions from "views/Pages/PortalPermissions.jsx";
import UserAdminPage from "views/Pages/UserAdminPage.jsx";
import Maintenance from "views/Pages/Maintenance.jsx";
import MaintenancePage from "views/Pages/MaintenancePage.jsx";
import SettingsPage from "views/Pages/SettingsPage.jsx";
import CRMContactsPage from "views/Pages/CRMContactsPage.jsx";
import CRMCompaniesPage from "views/Pages/CRMCompaniesPage.jsx";
import CRMPropertiesPage from "views/Pages/CRMPropertiesPage.jsx";
import CRMPropertyImporterPage from "views/Pages/CRMPropertyImporterPage.jsx";
import CRMLeasesPage from "containers/CRM/Properties/Lease/LeaseMain";
import CRMListingsPage from "containers/CRM/Properties/Listing/ListingMain";
import CRMMeetingsPage from "containers/CRM/Properties/Meeting/MeetingMain";
import CRMOffersPage from "containers/CRM/Properties/Offer/OfferMain";
import CRMShowingsPage from "containers/CRM/Properties/Showing/ShowingMain";
import CRMClosingsPage from "../views/Pages/CRMClosingsPage.jsx";
import ERTLPage from "views/Pages/ERTLPage.jsx";
import InvoicesPage from 'views/Pages/InvoicesPage.jsx';
import PMAPage from "views/Pages/PMAPage.jsx";
import OBPage from "views/Pages/OnboardingPage.jsx";
import RSheetsPage from "views/Pages/RSheetsPage.jsx";
import ListingSubmissionsPage from "../views/Pages/ListingSubmissionsPage.jsx";
import ContactsImportPage from '../views/Pages/ContactsImportPage.jsx';
import CommissionSplitsPage from "../views/Pages/CommissionSplitsPage.jsx";
import GCIPage from "../views/Pages/GCIPage.jsx";
import TRPage from "../views/Pages/TRPage.jsx";
import userIsLoggedIn from "shared/authValidation";
import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Build from '@material-ui/icons/Build';
import Image from '@material-ui/icons/Image';
import Apps from '@material-ui/icons/Apps';
import ContentPaste from '@material-ui/icons/ContentPaste';
import GridOn from '@material-ui/icons/GridOn';
import Place from '@material-ui/icons/Place';
import WidgetsIcon from '@material-ui/icons/Widgets';
import Timeline from '@material-ui/icons/Timeline';
import DateRange from '@material-ui/icons/DateRange';
import ContactsIcon from '@material-ui/icons/Contacts';
import Person from '@material-ui/icons/Person';
import Group from '@material-ui/icons/Group';
import People from '@material-ui/icons/People';

import checkPermissions from 'shared/checkPermissions';
import checkPermissionsActions from "shared/checkPermissionsActions";

const paths = {};

const dashRoutes = () => {
  const userAdminPermitted = checkPermissions('UserAdmin');
  const userPermissionsPermitted = checkPermissions('UserPerms');
  const developerPermitted = checkPermissions('Developer');
  const crmPermitted = checkPermissions('CRM');
  const crmActions = crmPermitted ? checkPermissionsActions('CRM') : null;
  const ertlPermitted = checkPermissions('ERTL');
  const routingPermitted = checkPermissions('Routing');
  const pmaPermitted = checkPermissions('PMA');
  const obPermitted = checkPermissions('Onboarding');
  const listingPermitted = checkPermissions('Listing');
  const dialpadPermitted = checkPermissions('Dialpad');
  const invoicePermitted = checkPermissions('Invoice');
  const commissionSplitsPermitted = checkPermissions('CommissionSplits');
  const trPermitted = checkPermissions('TR');
  const GCIPermitted = checkPermissions('GCI');

  return [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      permitted: true
    },
    {
      collapse: true,
      path: "/crm",
      name: "CRM",
      state: "openComponents",
      icon: Apps,
      permitted: crmPermitted,
      views: [
        {
          path: "/crm/contacts",
          name: "Contacts",
          mini: "CO",
          icon: Person,
          permitted2: crmPermitted,
          component: CRMContactsPage
        },
        // {
        //   path: '/crm/import',
        //   name: 'Contact Import',
        //   mini: 'CI',
        //   icon: People,
        //   permitted2: dialpadPermitted,
        //   component: ContactsImportPage,
        // },
        {
          path: "/crm/companies",
          name: "Companies",
          mini: "MA",
          fontawesome: "user-plus",
          permitted2: crmPermitted,
          component: CRMCompaniesPage
        },
        {
          path: "/crm/properties",
          name: "Properties",
          mini: "MA",
          fontawesome: "building",
          permitted2: crmPermitted,
          component: CRMPropertiesPage
        },
        {
          path: "/crm/leases",
          name: "Leases",
          mini: "MA",
          fontawesome: "credit-card",
          permitted2: crmPermitted,
          component: CRMLeasesPage
        },
        {
          path: "/crm/listings",
          name: "Listings",
          mini: "MA",
          fontawesome: "clipboard-list",
          permitted2: crmPermitted,
          component: CRMListingsPage
        },
        {
          path: "/crm/meetings",
          name: "Meetings",
          mini: "MA",
          icon: Group,
          permitted2: crmPermitted,
          component: CRMMeetingsPage
        },
        {
          path: "/crm/offers",
          name: "Offers",
          mini: "MA",
          fontawesome: "dollar-sign",
          permitted2: crmPermitted,
          component: CRMOffersPage
        },
        {
          path: "/crm/showings",
          name: "Showings",
          mini: "MA",
          fontawesome: "boxes",
          permitted2: crmPermitted,
          component: CRMShowingsPage
        },
        // {
        //   path: "/crm/closings",
        //   name: "Closings",
        //   mini: "CL",
        //   fontawesome: "boxes",
        //   permitted2: crmPermitted,
        //   component: CRMClosingsPage
        // }
        {
          path: "/crm/importer",
          name: "Import",
          mini: "PI",
          icon: ArrowDownwardIcon,
          permitted2: crmActions ? crmActions.hasOwnProperty('property_importer') 
            || crmActions.hasOwnProperty('othersources_importer') 
            || crmActions.hasOwnProperty('costar_importer')
          : 
            false,
          component: CRMPropertyImporterPage
        }
      ]
    },
    {
      collapse: true,
      path: "/documents",
      name: "Documents",
      state: "openDocuments",
      icon: Apps,
      permitted: ertlPermitted || pmaPermitted || routingPermitted || listingPermitted || commissionSplitsPermitted || trPermitted,
      views: [
        {
          path: "/pma",
          name: "PMA",
          mini: "PM",
          permitted2: pmaPermitted,
          component: PMAPage,
        },
        {
          path: "/ertl",
          name: "ERTL",
          mini: "ER",
          permitted2: ertlPermitted,
          component: ERTLPage,
        },
        {
          path: "/rs",
          name: "Routing Sheets",
          mini: "RS",
          permitted2: routingPermitted,
          component: RSheetsPage,
        },
        {
          path: "/listing",
          name: "Listing Submissions",
          mini: "LS",
          permitted2: listingPermitted,
          component: ListingSubmissionsPage,
        },
        {
          path: "/invoices",
          name: "Invoices",
          mini: "IN",
          permitted2: invoicePermitted,
          component: InvoicesPage,
        },
        {
          path: "/commission_splits",
          name: 'Commission Splits',
          mini: "CS",
          permitted2: commissionSplitsPermitted,
          component: CommissionSplitsPage
        },
        {
          path: "/tr",
          name: 'Tenant Rep',
          mini: "TR",
          permitted2: trPermitted,
          component: TRPage
        }
      ]
    },
    {
      path: "/ob",
      name: "Onboarding",
      icon: Apps,
      component: OBPage,
      permitted: obPermitted
    },
    {
      collapse: true,
      path: "/components",
      name: "Settings",
      state: "openComponents",
      icon: Apps,
      permitted: (developerPermitted),
      views: [
        {
          path: "/components/portal_settings",
          name: "Portal Settings",
          mini: "PM",
          permitted2: (developerPermitted),
          component: SettingsPage
        },
        {
          path: "/components/tool_maintenance",
          name: "Tool Maintenance",
          mini: "MA",
          permitted2: developerPermitted,
          component: Maintenance
        },
        {
          path: "/components/maintenance_page",
          name: "Maintenance Page",
          mini: "MP",
          permitted2: true,
          component: MaintenancePage,
          display: false
        },
        {
          path: "/components/panels",
          name: "Panels",
          mini: "P",
          permitted2: developerPermitted,
          component: Panels
        },
        {
          path: "/components/sweet-alert",
          name: "Sweet Alert",
          mini: "SA",
          permitted2: developerPermitted,
          component: SweetAlert
        },
        {
          path: "/components/notifications",
          name: "Notifications",
          mini: "N",
          permitted2: developerPermitted,
          component: Notifications

        },
        {
          path: "/forms/regular-forms",
          name: "Regular Forms",
          mini: "RF",
          permitted2: developerPermitted,
          component: RegularForms
        },
        {
          path: "/forms/extended-forms",
          name: "Extended Forms",
          mini: "EF",
          permitted2: developerPermitted,
          component: ExtendedForms
        },
        {
          path: "/forms/validation-forms",
          name: "Validation Forms",
          mini: "VF",
          permitted2: developerPermitted,
          component: ValidationForms
        },
        { path: "/forms/wizard", name: "Wizard", mini: "W", permitted2: developerPermitted, component: Wizard },
        {
          path: "/tables/regular-tables",
          name: "Regular Tables",
          mini: "RT",
          permitted2: developerPermitted,
          component: RegularTables
        },
        {
          path: "/tables/extended-tables",
          name: "Extended Tables",
          mini: "ET",
          permitted2: developerPermitted,
          component: ExtendedTables
        },
        {
          path: "/tables/react-tables",
          name: "React Tables",
          mini: "RT",
          permitted2: developerPermitted,
          component: ReactTables
        },
        {
          path: "/maps/google-maps",
          name: "Google Maps",
          mini: "GM",
          permitted2: developerPermitted,
          component: GoogleMaps
        },
        {
          path: "/maps/full-screen-maps",
          name: "Full Screen Map",
          mini: "FSM",
          permitted2: developerPermitted,
          component: FullScreenMap
        },
        {
          path: "/maps/vector-maps",
          name: "Vector Map",
          mini: "VM",
          permitted2: developerPermitted,
          component: VectorMap
        },
        { path: "/widgets", name: "Widgets", icon: WidgetsIcon, permitted2: developerPermitted, component: Widgets },
        { path: "/charts", name: "Charts", icon: Timeline, permitted2: developerPermitted, component: Charts },
        { path: "/calendar", name: "Calendar", icon: DateRange, permitted2: developerPermitted, component: Calendar },
        {
          collapse: true,
          path: "/tables",
          name: "Tables",
          state: "openTables",
          icon: GridOn,
          permitted2: developerPermitted,
          views: [
          ]
        },
        {
          collapse: true,
          path: "/maps",
          name: "Maps",
          state: "openMaps",
          icon: Place,
          permitted2: developerPermitted,
          views: [
          ]
        },
        {
          path: "/rtl/rtl-support-page",
          name: "RTL Support",
          mini: "RS",
          permitted2: developerPermitted,
          component: RTLSupport
        },
        {
          path: "/timeline-page",
          name: "Timeline Page",
          mini: "TP",
          component: TimelinePage
        }
      ]
    },
    {
      collapse: true,
      path: "/admin",
      name: "Admin",
      state: "openForms",
      icon: ContentPaste,
      permitted: (userAdminPermitted || userPermissionsPermitted || GCIPermitted),
      views: [
         {
            path: "/user-permissions",
            name: "User Permissions",
            mini: "UP",
            component: PortalPermissions,
            permitted2: userPermissionsPermitted
          },
         {
           path: "/user-admin",
           name: "User Admin",
           mini: "UR",
           component: UserAdminPage,
           permitted2: userAdminPermitted
         },
         {
           path: "/gci",
           name: "GCI",
           mini: "GC",
           component: GCIPage,
           permitted2: GCIPermitted
         }
      ]
    },
    { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
  ];
}
export default dashRoutes;

const ds = dashRoutes();
ds.forEach(d => {
  if (d.collapse) {
    d.views.forEach(dv => {
      paths[dv.path] = d.state;
    })
  }
});

export const collapsePaths = paths;
