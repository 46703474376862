import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
const extraMsg = 'Please try again. If the issue persists please contact tech support.';

const initialState = {
	appointmentsData: [],
	appointmentData: null,
	appointmentsCount: null,
	loading: false,
	loadingAppointment: false,
	success: null,
	error: null
};

// GET ALL APPOINTMENTS

const getAppointmentsStart = (state) => {
	return updateObject(state, {
		loading: true,
		appointmentsData: [],
		appointmentsCount: null,
		error: null,
		success: null,
	});
};

const getAppointmentsSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		appointmentsData: action.response,
		appointmentsCount: action.response.length,
	});
};

const getAppointmentsFail = (state) => {
	return updateObject(state, {
		loading: false,
		error: `Failed to get appointments. ${extraMsg}`
	});
};

// GET APPOINTMENT BY EVENT ID

const getAppointmentStart = (state) => {
	return updateObject(state, {
		loadingAppointment: true,
		appointmentData: null,
		error: null,
		success: null,
	});
};

const getAppointmentSuccess = (state, action) => {
	return updateObject(state, {
		loadingAppointment: false,
		appointmentData: action.response,
	});
};

const getAppointmentFail = (state, action) => {
	return updateObject(state, {
		loadingAppointment: false,
		appointmentData: null,
		error: action.err
	});
};

const getAppointmentClear = (state) => {
	return updateObject(state, {
		loadingAppointment: false,
		appointmentData: null
	});
};

// CREATE APPOINTMENT

const createAppointmentStart = (state) => {
	return updateObject(state, {
		error: null,
		success: null
	});
};

const createAppointmentSuccess = (state) => {
	return updateObject(state, {
		success: `Successfully created appointment`
	});
};

const createAppointmentFail = (state, action) => {
	return updateObject(state, {
		error: action.err
	});
};

// UPDATE APPOINTMENT

const updateAppointmentStart = (state) => {
	return updateObject(state, {
		error: null,
		success: null
	});
};

const updateAppointmentSuccess = (state) => {
	return updateObject(state, {
		success: `Successfully updated appointment`
	});
};

const updateAppointmentFail = (state, action) => {
	return updateObject(state, {
		error: action.err
	});
};

// DELETE APPOINTMENT

const deleteAppointmentStart = (state) => {
	return updateObject(state, {
		success: null,
		error: null
	});
};

const deleteAppointmentSuccess = (state) => {
	return updateObject(state, {
		success: `Successfully deleted appointment`
	});
};

const deleteAppointmentFail = (state, action) => {
	return updateObject(state, {
		error: action.err
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CALENDAR_APPOINTMENTS_START:
			return getAppointmentsStart(state);
		case actionTypes.GET_CALENDAR_APPOINTMENTS_SUCCESS:
			return getAppointmentsSuccess(state, action);
		case actionTypes.GET_CALENDAR_APPOINTMENTS_FAIL:
			return getAppointmentsFail(action);

		case actionTypes.GET_CALENDAR_APPOINTMENT_START:
			return getAppointmentStart(state);
		case actionTypes.GET_CALENDAR_APPOINTMENT_SUCCESS:
			return getAppointmentSuccess(state, action);
		case actionTypes.GET_CALENDAR_APPOINTMENT_FAIL:
			return getAppointmentFail(state, action);
		case actionTypes.GET_CALENDAR_APPOINTMENT_CLEAR:
			return getAppointmentClear(state);

		case actionTypes.CREATE_CALENDAR_APPOINTMENT_START:
			return createAppointmentStart(state);
		case actionTypes.CREATE_CALENDAR_APPOINTMENT_SUCCESS:
			return createAppointmentSuccess(state);
		case actionTypes.CREATE_CALENDAR_APPOINTMENT_FAIL:
			return createAppointmentFail(state, action);

		case actionTypes.UPDATE_CALENDAR_APPOINTMENT_START:
			return updateAppointmentStart(state);
		case actionTypes.UPDATE_CALENDAR_APPOINTMENT_SUCCESS:
			return updateAppointmentSuccess(state);
		case actionTypes.UPDATE_CALENDAR_APPOINTMENT_FAIL:
			return updateAppointmentFail(state, action);

		case actionTypes.DELETE_CALENDAR_APPOINTMENT_START:
			return deleteAppointmentStart(state);
		case actionTypes.DELETE_CALENDAR_APPOINTMENT_SUCCESS:
			return deleteAppointmentSuccess(state);
		case actionTypes.DELETE_CALENDAR_APPOINTMENT_FAIL:
			return deleteAppointmentFail(state, action);
		default:
			return state;
	}
};

export default reducer;
