import React, { useEffect, useState } from 'react';
import Table from "components/Table/Table.jsx";
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Datetime from 'react-datetime';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import CustomInput from '../../../components/CustomInput/CustomInputValidate';
import { 
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
} from "shared/validation";
import { clone } from 'shared/utility';
import moment from 'moment';
import { updateTheObj } from '../../../shared/utility';
const validate = {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
}

const AppointmentModal = (props) => {
    const { start, end, summary, description, index } = props.appointment;
    const [theStart, setTheStart] = useState(start ? start : '');
    const [theEnd, setTheEnd] = useState(end ? end : '');
    const [theSummary, setTheSummary] = useState(summary ? summary : '');
    const [theDescription, setTheDescription] = useState(description ? description : '');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!start) setTheStart(new Date());
        if (!end) setTheEnd(new Date());
    }, []);

    return (
        <Dialog
            fullWidth 
            maxWidth='sm'
            open={props.open}
        >
            <div style={{padding:20}}>
                <div className="close-btn" onClick={() => { props.close() }}><Close /></div>
            </div>

            <DialogTitle>
                <div style={{textAlign: 'center', fontSize: '1.8rem'}}>Appointment</div>
            </DialogTitle>
            <DialogContent style={{height: '450px'}}>
                <div style={{textAlign: 'center', padding: 20}}>
                    <div style={{display: 'flex', padding: '.75rem 0'}}>
                        <div style={{flex: 1, marginLeft: '.45', textAlign: 'left'}}>
                            <label>Start</label>
                            <Datetime
                                closeOnSelect={true}
                                inputProps={{readOnly: true}}
                                value={theStart}
                                locale='en-US'
                                onChange={(e) => {
                                    if (theEnd < e) {
                                        return setError('Please make sure end date/time is after date/time');
                                    }
                                    setError('');
                                    setTheStart(e);
                                }}
                            />
                        </div>
                        <div style={{flex: 1, marginLeft: '.45rem', textAlign: 'left'}}>
                            <label>End</label>
                            <Datetime
                                closeOnSelect={true}
                                inputProps={{readOnly: true}}
                                value={theEnd}
                                locale='en-US'
                                onChange={(e) => {
                                    if (theStart > e) {
                                        return setError('Please make sure end date/time is after date/time');
                                    }
                                    setError('');
                                    setTheEnd(e);
                                }}
                            />
                        </div>
                    </div>
                    <CustomInput
                        label='Summary'
                        value={theSummary}
                        name='summary'
                        onChange={(e) => {
                            setTheSummary(e.target.value);
                        }}
                        validate={[required]}
                    />
                    <CustomInput
                        error={true}
                        label='Description'
                        value={theDescription}
                        onChange={(e) => {
                            setTheDescription(e.target.value)
                        }}
                        name='description'
                    />

                    {error && <div className="error-text pad-20">{error}</div>}

                    <Button color='primary' style={{marginRight: 10, marginTop: 20}} onClick={() => {
                        const newObj = {
                            start: theStart,
                            end: theEnd,
                            summary: theSummary,
                            description: theDescription,
                            name: props.contactName
                        };
                        if (!theSummary) return null;
                        if (theStart > theEnd) {
                            
                            return setError('Please make sure end date/time is after date/time');
                        }
                        if (index === -1) {
                            const newAppointments = clone(props.appointmentsForAdd);
                            
                            props.setAppointmentsForAdd(newAppointments.concat([newObj]));
                        } else {
                            const newAppointments = clone(props.appointmentsForAdd);
                            newAppointments[index] = newObj;
                            props.setAppointmentsForAdd(newAppointments);
                        }
                        props.close();
                    }}>
                        {index === -1 ? 'Add' : 'Update'}
                    </Button>
                    <Button
                        color='white'
                        style={{marginRight: 10, marginTop: 20}}
                        onClick={() => {
                            props.close();
                        }}
                    >
                        Close
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

const ContactsAppointmentsAdd = (props) => {
    const [openedModal, setOpenedModal] = useState(false);
    const [appointment, setAppointment] = useState({});

    

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Button 
                    color="primary"
                    onClick={() => {
                        setAppointment({
                            start: '',
                            end: '',
                            summary: '',
                            description: '',
                            index: -1
                        })
                        setOpenedModal(true)
                    }}
                >
                    <Add /> NEW APPOINTMENT
                </Button>
                <Table 
                    tableHeaderColor="primary"
                    tableHead={[
                      <strong>Title</strong>,
                      <strong>
                          Description
                      </strong>,
                      <strong>Start</strong>,
                      <strong>End</strong>
                  ]}
                    tableData={props.appointmentsForAdd.map((afa,i) => {
                        const editAppointment = () => {
                            setAppointment(updateTheObj(afa, {
                                index: i
                            }));
                            setOpenedModal(true);
                        }
                        return [
                            <div className="hovered"
                                onClick={editAppointment}>{afa.summary}</div>,
                            <div className="hovered"
                                onClick={editAppointment}>{afa.description}</div>,
                            <div className="hovered"
                                onClick={editAppointment}>{moment(afa.start).format('MM/DD/YYYY h:mma')}</div>,
                            <div className="hovered"
                                onClick={editAppointment}>{moment(afa.end).format('MM/DD/YYYY h:mma')}</div>
                        ]
                    })}
                />
            </GridItem>

            {openedModal && <AppointmentModal 
                open={openedModal}
                close={() => {
                    setOpenedModal(false);
                }}
                appointment={appointment}
                setAppointment={setAppointment}
                appointmentsForAdd={props.appointmentsForAdd}
                setAppointmentsForAdd={props.setAppointmentsForAdd}
                contactName={props.contactName}
            />}
        </GridContainer>
    )
}

export default ContactsAppointmentsAdd;