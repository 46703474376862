import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import {
	withStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from "@material-ui/core";
import ContactsCreateAppointmentDialog from "./ContactsCreateAppointmentDialog";
import tableStyle from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { Edit, Delete } from "@material-ui/icons";
import "react-datetime/css/react-datetime.css";
import {
	getAppointment,
	deleteAppointment,
} from "../../../store/actions/crm_appointments";
import CustomDialogConfirm from "../../../components/CustomDialog/CustomDialogConfirm";

const CustomTable = (props) => {
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [passedData, setPassedData] = useState(null);

	const {
		classes,
		tableHead,
		tableData,
		tableHeaderColor,
		hover,
		colorsColls,
		coloredColls,
		customCellClasses,
		customClassesForCells,
		striped,
		tableShopping,
		customHeadCellClasses,
		customHeadClassesForCells,
		tableHeaderClasses,
		contactId,
		contactName,
		edit,
		contactOptions,
		getAppointment,
		deleteAppointment,
		createModal,
		setCreateModal,
		setDelState,
		setUpdateState,
		setCreateState,
		// contactData,
		getContacts,
		getContact,
		emailContacts,
		contactsData,
		getAppointments,
		upcomingState,
		crm_appointments,
		canEdit
	} = props;

	const { appointmentData } = crm_appointments;

	const currentDate = new Date().getDate();
	const pastYear = new Date().setDate(currentDate - 365);

	const timeParams = {
		timeMin: upcomingState
			? new Date().toISOString()
			: new Date(pastYear).toISOString(),
		timeMax: upcomingState ? null : new Date().toISOString(),
	};

	

	return (
		<div style={{ width: "100%" }} className={classes.tableResponsive}>
			<Table className={classes.table}>
				{tableHead !== undefined ? (
					<TableHead
						className={[classes[tableHeaderColor], ...tableHeaderClasses].join(
							" "
						)}
					>
						<TableRow className={classes.tableRow}>
							{tableHead.map((prop, key) => {
								const tableCellClasses =
									classes.tableHeadCell +
									" " +
									classes.tableCell +
									" " +
									cx({
										[customHeadCellClasses[
											customHeadClassesForCells.indexOf(key)
										]]: customHeadClassesForCells.indexOf(key) !== -1,
										[classes.tableShoppingHead]: tableShopping,
										[classes.tableHeadFontSize]: !tableShopping,
									});
								return (
									<TableCell className={tableCellClasses} key={key}>
										{prop}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
				) : null}
				<TableBody>
					{tableData.map((prop, key) => {
						var rowColor = "";
						var rowColored = false;
						if (prop.color !== undefined) {
							rowColor = prop.color;
							rowColored = true;
							prop = prop.data;
						}
						const descArr = prop.description
							.split("---")
							.filter((item) => item !== "");
						const formattedDescription = descArr[descArr.length - 3].trim();
						const formattedInvitees = descArr[descArr.length - 2].trim() !== "" ? descArr[descArr.length - 2].trim().split(",") : [];

						const descContactId = descArr[0].trim();
						const formattedStart = new Date(prop.start.dateTime).toLocaleString(
							"en-US",
							{
								timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
								dateStyle: "short",
								timeStyle: "short",
							}
						);
						const formattedEnd = new Date(prop.end.dateTime).toLocaleString(
							"en-US",
							{
								timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
								dateStyle: "short",
								timeStyle: "short",
							}
						);
						const transformeddescContact = parseInt(descContactId);
						const tableRowClasses = cx({
							[classes.tableRowHover]: hover,
							[classes[rowColor + "Row"]]: rowColored,
							[classes.tableStripedRow]: striped && key % 2 === 0,
						});
						const propsToPass = {
							id: prop.id,
							summary: prop.summary,
							description: formattedDescription,
							start: formattedStart,
							end: formattedEnd,
							invitees: formattedInvitees,
						};
						const idsMatch = transformeddescContact === contactId;
						return (
							<Fragment key={key}>
								{idsMatch ? (
									<TableRow
										style={{ position: "relative" }}
										key={key}
										hover={hover}
										className={tableRowClasses}
									>
										<TableCell style={{ paddingLeft: ".35rem" }}>
											{prop.summary}
										</TableCell>
										<TableCell style={{ paddingLeft: ".35rem" }}>
											{formattedDescription}
										</TableCell>
										<TableCell style={{ paddingLeft: ".35rem" }}>
											{formattedStart}
										</TableCell>
										<TableCell style={{ paddingLeft: ".35rem" }}>
											{formattedEnd}
										</TableCell>
										<TableCell style={{ paddingLeft: ".35rem" }}>
											{canEdit && <div style={{ display: "flex" }}>
												<div
													onClick={async () => {
														
														setEditModal(true);
														getAppointment(prop.id);
														setPassedData(propsToPass);
													}}
													className="blue-text inline-block mr-20 hoverable"
												>
													<Tooltip
														id="tooltip-edit-appointment"
														title="Edit Appointment"
														placement="top"
													>
														<Edit />
													</Tooltip>
												</div>
												<div
													onClick={() => {
														setDeleteModal(true);
														setDeleteId(prop.id);
													}}
													className="red-text inline-block hoverable"
												>
													<Tooltip
														id="tooltip-delete-appointment"
														title="Delete Appointment"
														placement="top"
													>
														<Delete />
													</Tooltip>
												</div>
											</div>}
										</TableCell>
									</TableRow>
								) : null}
							</Fragment>
						);
					})}
				</TableBody>
			</Table>
			<CustomDialogConfirm
				deleteId={deleteId}
				setDelState={setDelState}
				deleteAppointment={deleteAppointment}
				openConfirm={deleteModal}
				closeConfirm={() => {
					getAppointments(timeParams);
					setDeleteModal(false);
				}}
			/>
			<ContactsCreateAppointmentDialog
				title="Edit Appointment"
				editMode
				passedData={passedData}
				contactId={contactId}
				contactName={contactName}
				setUpdateState={setUpdateState}
				appointmentData={appointmentData}
				options={contactOptions ? contactOptions.useTypeOptions : []}
				open={editModal}
				close={() => setEditModal(false)}
				contactsData={contactsData}
				getContacts={getContacts}
				getContact={getContact}
				emailContacts={emailContacts}
			/>
			<ContactsCreateAppointmentDialog
				title="New Appointment"
				setCreateState={setCreateState}
				contactId={contactId}
				contactName={contactName}
				options={contactOptions ? contactOptions.useTypeOptions : []}
				open={createModal}
				contactsData={contactsData}
				getContacts={getContacts}
				getContact={getContact}
				close={() => setCreateModal(false)}
				emailContacts={emailContacts}
			/>
		</div>
	);
};

CustomTable.defaultProps = {
	tableHeaderColor: "gray",
	tableHeaderClasses: [],
	hover: false,
	colorsColls: [],
	coloredColls: [],
	striped: false,
	customCellClasses: [],
	customClassesForCells: [],
	customHeadCellClasses: [],
	customHeadClassesForCells: [],
	showTableBody: true,
};

CustomTable.propTypes = {
	classes: PropTypes.object.isRequired,
	tableHeaderColor: PropTypes.oneOf([
		"warning",
		"primary",
		"danger",
		"success",
		"info",
		"rose",
		"gray",
	]),
	tableHead: PropTypes.array,
	// Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
	tableData: PropTypes.array,
	hover: PropTypes.bool,
	coloredColls: PropTypes.arrayOf(PropTypes.number),
	// Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
	colorsColls: PropTypes.array,
	customCellClasses: PropTypes.arrayOf(PropTypes.string),
	customClassesForCells: PropTypes.arrayOf(PropTypes.number),
	customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
	customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
	striped: PropTypes.bool,
	// this will cause some changes in font
	tableShopping: PropTypes.bool,
	deleteAppointment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	crm_appointments: state.crm_appointments,
	emailContacts: state.crm_contacts.emailContacts
});

export default withStyles(tableStyle)(
	connect(mapStateToProps, { getAppointment, deleteAppointment })(CustomTable)
);
