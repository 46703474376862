import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import showingFields, { initialShowingData } from './ShowingModel';
import ShowingModal from "./ShowingModal";
import TextField from '@material-ui/core/TextField';
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import Delete from "@material-ui/icons/Delete";
import Person from "@material-ui/icons/Person";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import Datetime from 'react-datetime';
import { formatDateForBackend, formatDate2, checkDateOrder2 } from 'shared/utility';
import Close from '@material-ui/icons/Close';
import { clone, formatDate4, formatTime2 } from 'shared/utility';
import FilesForm from './FilesForm';
import { useSaveShowing } from './ShowingLogic';
import { getUser } from '../../../../shared/authValidation';
import { Tabs, Tab } from "@material-ui/core";
import Time from '../../../../components/Time/Time';
import {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
} from "shared/validation";
import Loader from '../../../../components/Loader/Loader';
const validate = {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
}

const getValidations = (f) => {
    return showingFields[f].validations.map(v => validate[v])
}
const ShowingForm = (props) => {
    const { showingData, action, setTab, canEdit, canDelete, getShowings, setMsgsForAdd, setUserType,
        createShowing, updateShowing, propertyID, getShowing, deleteShowing,
        error, success } = props;

    const [data, setData] = useState(initialShowingData);
    const [formDataObj, setFormDataObj] = useState(null);
    const [openedSearchModal, setOpenedSearchModal] = useState(false);
    const [openedModal, setOpenedModal] = useState(false);
    const [people, setPeople] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [madeChanges, setMadeChanges] = useState(false);
    const [curTab, setCurTab] = useState('Info');
    const [openTime, setOpenTime] = useState('');

    const [guests, setGuests] = useState([]);

    useSaveShowing({
        madeChanges,
        updateShowing,
        data,
        setMadeChanges
    })

    const updateDataObj = (field, value) => {
        const newData = { ...data };
        newData[field] = value;
        setData(newData);
        setMadeChanges(true);
    }

    const updateDataObjs = (obj) => {
		const newData = clone(data);
		Object.keys(obj).forEach(field => {
			newData[field] = obj[field];
		})
		setData(newData);
		setMadeChanges(true);
	};

    const cleanData = (data) => {
        // removes not editable dates and combines date and time
		const newData = clone(data);
		newData.start_datetime = formatDateForBackend(
			newData.start_date,
			newData.start_time
		);
		delete newData.start_date;
		delete newData.start_time;

		newData.end_datetime = formatDateForBackend(
			newData.end_date,
			newData.end_time
		);
		delete newData.end_date;
		delete newData.end_time;

		if (newData.date_created) {
			delete newData.date_created;
		}
		if (newData.date_modified) {
			delete newData.date_modified;
		}
		return newData;
	};

    const doUpdate = () => {
        const newData = cleanData(data);
        newData.guests = JSON.stringify(data.guests);
        if(formDataObj){
            newData['formDataObj'] = formDataObj;
        }
        updateShowing(newData);
        setMadeChanges(false);
    }

    const doAdd = () => {
        const newData = clone(data);
        newData.guests = JSON.stringify(data.guests);
        delete newData.id;
        newData.property_id = propertyID;
        if(formDataObj){
            newData['formDataObj'] = formDataObj;
        }
        createShowing(cleanData(newData));
        setMadeChanges(false);
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevError = usePrevious(error);
    const prevSuccess = usePrevious(success);

    useEffect(() => {
        if (prevError === null && error) {
            setErrorMsg(error);
        }
    }, [error]);

    useEffect(() => {
        
        if (prevSuccess === null && success) {
            setSuccessMsg(success);
            if (action === 'Add') {
                setData({ ...initialShowingData });
                getShowings();
                setTab('List');
            }
        }
    }, [success]);

    useEffect(() => {
        if (data) {
            let errors = '';
            ['start_date'].forEach(d => {
                if (data[d]) {
                    const isRealDate = moment(data[d], "MM/DD/YYYY", true).isValid();
                    if (!isRealDate) {
                        errors += `Date must be a valid MM/DD/YYYY formatted date. `;
                    }
                } else {
                    errors += `Start Date is required. `;
                }
            });
            if (data.start_date && data.end_date) {
				if (!checkDateOrder2(data)) {
					errors += `Invalid date range. Start Date/Time must be before End Date/Time`;
				}
			}
            setErrorMsg(errors);
        }
    })

    useEffect(() => {
        if (showingData && (action === 'Edit' || action === 'View')) {
            const newData = clone(showingData);
			// reformat to MM/DD/YYYY
			if (newData["start_datetime"]) {
				newData.start_date = formatDate4(newData.start_datetime);
				newData.start_time = formatTime2(newData.start_datetime, 'no_utc');
			}
            if (newData["end_datetime"]) {
				newData.end_date = formatDate4(newData.end_datetime);
				newData.end_time = formatTime2(newData.end_datetime, 'no_utc');
			}
			setData(newData);
        } else if (showingData && action === 'Add') {
            setData({ ...initialShowingData });
            setSuccessMsg('');
        }
    }, [action]);

    useEffect(() => {
        setMsgsForAdd({ error: null, success: null });
        setSuccessMsg('');
    }, [])

    const cancel = () => {
        if (madeChanges && props.setOpenedAsk) {
            props.setOpenedAsk(true);
        } else {
            getShowings();
            setTab('List');
            if (props.user) {
                if (props.user.type === 'A') {
                    if (setUserType) setUserType('own');
                }
            }
        }
    }

    const getTabIndex = () => {
        if (curTab === 'Info') {
            return 0;
        }
        if (curTab === 'Files') {
            return 1;
        }
    }

    const choose = (chosen) => {
        let newGuests = clone(data.guests);
        if (!newGuests.some(ng => ng.id === chosen.id)) {
            newGuests.push({
                id: chosen.id, 
                name: chosen.name,
                email: chosen.email
            });
            updateDataObj('guests', newGuests);
        }
        setOpenedSearchModal(false)
    }

    let show_illi_owned_message = false;
    const user_logged_in = getUser();
    if (user_logged_in) {
        if (user_logged_in.type) {
            if (user_logged_in.type === 'A') {
                if (props.showingData) {
                    if (props.showingData.property_user_id === 1) {
                        show_illi_owned_message = true;
                    }
                }
            }
        }
    }

    console.log('ShowingForm', props);

    return (
        <div>
            {props.showCloseBtn && <div className="close-btn" onClick={() => { cancel() }}><Close /></div>}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h3 className="inline-block">
                        {<div
                            className="inline-block blue-text hoverable mr-20"
                            onClick={() => {
                                cancel();
                            }}
                        >
                            <Tooltip title='Back to Showing List' placement='top'>
                                <ArrowBack />
                            </Tooltip>
                        </div>}
                        {action !== 'Add' && <div
                            className={`${action === 'View' ? 'orange' : 'lightgray'}-text inline-block mr-20 hoverable`}
                            onClick={() => {
                                setTab('View');
                            }}
                        >
                            <Tooltip
                                title="View Showing"
                                placement="top"
                            >
                                <Visibility />
                            </Tooltip>
                        </div>}
                        {(action !== 'Add' && canEdit) && <div
                            className={`${action === 'Edit' ? 'orange' : 'lightgray'}-text inline-block mr-20 hoverable`}
                            onClick={() => {
                                setTab('Edit');
                            }}
                        >
                            <Tooltip
                                title="Edit Showing"
                                placement="top"
                            >
                                <Edit />
                            </Tooltip>
                        </div>}
                        {action} Showing 
                            {props.property_name ? <span> for <strong>{`${props.property_name}`}</strong></span> : ''}
                            {props.showingData ? 
                                props.showingData.name ? <span> for <strong>{`${props.showingData.name}`}</strong></span> : ''
                            : ''
                            }
                    </h3>
                    {(action !== 'Add' && canDelete) && <span
                        onClick={() => {
                            setOpenedModal(true);
                        }}
                        style={{
                            marginLeft: 20,
                            marginTop: 20,
                            float: 'right',
                            fontSize: 11,
                            fontWeight: 'bold'
                        }}
                        className="red-text hoverable"
                    >DELETE SHOWING</span>}
                    {show_illi_owned_message && <div>
					    {<div><strong>illi Company Data</strong></div>}
                    </div>}
                </GridItem>
                {<Tabs
                    TabIndicatorProps={{ style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' } }}
                    style={{ marginBottom: 10 }}
                    value={getTabIndex()}
                >
                    <Tab
                        onClick={() => {
                            setCurTab('Info');
                        }}
                        style={{ color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                        tabIndex={0}
                        label='Showing Info'
                        icon={<i className="fas fa-info-circle font-22"></i>}
                    />
                    {props.propertyAction !== 'Add' && <Tab
                        onClick={() => {
                            setCurTab('Files');
                        }}
                        style={{ color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                        tabIndex={1}
                        label='Files'
                        icon={<i className="fas fa-file font-22"></i>}
                    />}
                </Tabs>}
            </GridContainer>
            {curTab === 'Info' &&
                <div className={action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
                    <GridContainer>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.date && <div className="tiny-label">Start Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObjs({
												start_date: stringDate,
												end_date: stringDate
											});
                                        }
                                        updateDataObj('start_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.start_date}
                                />
                            </div>
                            <small className="gray-text">Start Time</small>
							{<div className="time-hover"
								onClick={() => {
									setOpenTime('start');
								}}
							>
									{data.start_time}
							</div>}
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.date && <div className="tiny-label">End Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "End Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('end_date', stringDate);;
                                        }
                                        updateDataObj('end_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.end_date}
                                />
                            </div>
                            <small className="gray-text">End Time</small>
							{<div className="time-hover"
								onClick={() => {
									setOpenTime('end');
								}}
							>
									{data.end_time}
							</div>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="custom-textfield">
                                <TextField
                                    label="Notes"
                                    multiline
                                    rowsMax="4"
                                    value={data.notes}
                                    onChange={(e) => {
                                        updateDataObj('notes', e.target.value)
                                    }}
                                    className=""
                                    margin="normal"
                                    validate={getValidations('notes')}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={4} md={4}>
                            <div className="carded">
                                <h4>{data.guests.length} Guest(s)</h4>
                                {action !== 'View' && <Button color="primary"
                                    onClick={() => {
                                        setOpenedSearchModal(true);
                                    }}
                                ><i className="fa fa-plus"></i> Add Guest</Button>}
                                <List>
                                    {data.guests.map((a, i) => {
                                        return <ListItem
                                            button
                                            key={`guest-${i}`}
                                        >
                                            <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>
                                            <ListItemText inset primary={`${a.name} - ${a.email}`} />
                                            <ListItemSecondaryAction>
                                                <Delete
                                                    className="hoverable red-text"
                                                    onClick={() => {
                                                        let newGuests = clone(data.guests);
                                                        newGuests.splice(i, 1);
                                                        updateDataObj('guests', newGuests);
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    })}
                                </List>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <hr />
                            {props.loading && <Loader />}
                            {!props.loading && <div>
                                {(errorMsg && !successMsg) && <div className="red-text" style={{ margin: 20 }}>{errorMsg}</div>}
                                {successMsg && <div className="green-text" style={{ margin: 20 }}>{successMsg}</div>}
                            </div>}
                        </GridItem>
                        {(!props.loading && (action === 'Edit' || action === 'Add')) && <GridItem xs={12} sm={12} md={12}>
                            <Button color="primary"
                                disabled={errorMsg ? true : false}
                                onClick={() => {
                                    if (action === 'Edit') {
                                        doUpdate();
                                    } else {
                                        doAdd();
                                    }
                                }}
                                className="mr-20"
                            >{
                                    action === 'Edit' ? 'Update Showing' : 'Add New Showing'
                                }</Button>
                            <Button color="white"
                                onClick={() => {
                                    cancel();
                                }}
                            >CANCEL</Button>
                        </GridItem>}
                    </GridContainer>

                    {openedSearchModal && <CustomDialog
                        open={openedSearchModal}
                        close={() => { setOpenedSearchModal(false) }}
                        choose={choose}
                        create_and_choose={choose}
                        title={`Select Guests`}
                        label={`Search Guests`}
                        current=""
                        email_is_required={true}
                    />}

                </div>
            }

            {curTab === 'Files' &&
                <FilesForm
                    showingData={props.showingData}
                    fileList={props.fileList}
                    getCrmFileList={props.getCrmFileList}
                    getCrmFile={props.getCrmFile}
                    createCrmFile={props.createCrmFile}
                    deleteCrmFile={props.deleteCrmFile}
                    contactSuccess={props.contactSuccess}
                    contactError={props.contactError}
                    contactLoading={props.contactLoading}
                    downloadedFile={props.downloadedFile}
                    formDataObj={formDataObj}
                    setFormDataObj={setFormDataObj}
                    action={action}
                />
            }

            {openedModal && <div>
                <ShowingModal
                    open={openedModal}
                    close={() => {
                        setOpenedModal(false);
                    }}
                    data={data}
                    setTab={setTab}
                    error={error}
                    success={success}
                    prevSuccess={prevSuccess}
                    prevError={prevError}
                    deleteShowing={deleteShowing}
                    showingID={data.id}
                    getShowings={getShowings}
                />
            </div>}

            {openTime === 'start' && <Time 
                label="Start Time"
                open={true}
                close={() => {
                    setOpenTime('');
                }}
                setTime={(e) => {
                    updateDataObj("start_time", e);
                    setOpenTime('');
                }}
                time={data.start_time}
            />}

            {openTime === 'end' && <Time 
                label="End Time"
                open={true}
                close={() => {
                    setOpenTime('');
                }}
                setTime={(e) => {
                    updateDataObj("end_time", e);
                    setOpenTime('');
                }}
                time={data.end_time}
            />}
            
        </div>
    )
}

const mapStateToProps = state => {
	const s = state.crm_showings;
	return {
		loading: s.loading,
	}
}

export default connect(mapStateToProps, null)(ShowingForm);