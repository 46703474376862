import React from 'react';
import { useEffect, useState } from 'react';
import { clone, chk } from '../../../shared/utility';
import Button from 'components/CustomButtons/Button.jsx';
import ContactBox from './ContactBox';
import { Dialog } from "@material-ui/core";
import CustomDialogWithContact from '../../../components/CustomDialog/CustomDialogWithContact';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import PropertiesEditContact from '../../CRM/Properties/PropertiesEditContact';
import { Close } from '@material-ui/icons';
import Notification from '../../../components/Notification/NotificationFixedTop';

const ContactsImporter = (props) => {
    const [currentContact, setCurrentContact] = useState(null);
    const [qList, setQList] = useState([]);
    const [skipped, setSkipped] = useState([]);
    const [qNum, setQNum] = useState(0);
    const [openedContactModal, setOpenedContactModal] = useState(false);
    const [openedExistingContact, setOpenedExistingContact] = useState(null);
    const [notification, setNotification] = useState('');

    const { contactsToImport, setContactsToImport } = props;

    const chooseContact = (chosenObj, chosenType) => {
        setOpenedContactModal(false);
        const newChosenObj = clone(chosenObj);
        const newCurrentContact = clone(currentContact);
        // attach Contact object for further reference
        newCurrentContact.chosen = newChosenObj;
        newCurrentContact.chosenType = chosenType;
        props.setAlreadyImportedList(props.alreadyImportedList.concat(newCurrentContact));
        // remove from queue
        setContactsToImport(props.contactsToImport.filter(c => {
            return currentContact.id !== c.id
        }));
        const newQlist = qList.filter(c => {
            let pass = false;
            if (c.id !== currentContact.id) {
                pass = true;
            }
            return pass;
        });
        setQList(newQlist);
        // Auto open next
        if (newQlist[qNum]) {
            // automatically open next contact in queue
            setNotification('Contact Imported!');
            setCurrentContact(newQlist[qNum]);
            setOpenedContactModal(true);
        }
    };

    useEffect(() => {
        setCurrentContact(contactsToImport[0]);
        setQList(contactsToImport);
        setOpenedContactModal(true);
    }, []);

    const skip = (cti) => {
        setOpenedContactModal(false);
        setSkipped(skipped.concat(cti));
        setContactsToImport(props.contactsToImport.filter(c => {
            return cti.id !== c.id
        }));
        if (qList.length === 0) {
            return;
        }
        const newQlist = qList.filter(c => {
            let pass = false;
            if (c.id !== cti.id) {
                pass = true;
            }
            return pass;
        });
        setQList(newQlist);
        if (newQlist[qNum]) {
            setCurrentContact(newQlist[qNum]);
        } else {
            setCurrentContact(newQlist[0]);
        }
    }

    console.log('ContactsImporter', props, skipped);

    return (
        <div>
            <Dialog
                maxWidth={false}
                open={true}
                onClose={() => {
                    props.onClose();
                }}
                fullWidth={true}
            >
                <div className="close-btn" onClick={() => { props.onClose(); }}><Close /></div>
                <div className="dialog-wrapper" style={{paddingLeft:40,paddingRight:40}}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h2 className="text-center">Contacts Importer</h2>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <h3>To Be Imported</h3>
                            <div style={{marginBottom:10}}>
                                {qList.length > 0 && <div>
                                    {qList.length} contact(s) left to be imported
                                </div>}
                                {qList.length === 0 && <div>
                                    {qList.length} contact(s)
                                </div>}
                            </div>
                            {props.contactsToImport.map((cti,i) => {
                                return <div key={`${i}-cti`} className="boxed">
                                    <h4>#{i+1}</h4>
                                    <ContactBox 
                                        obj={cti}
                                    />
                                    <Button
                                        color="rose"
                                        style={{marginRight:20}}
                                        onClick={() => { 
                                            setCurrentContact(cti);
                                            setOpenedContactModal(true); 
                                        }}
                                    >IMPORT</Button>
                                    <Button
                                        color="white"
                                        style={{marginRight:20}}
                                        onClick={() => {
                                            skip(cti);
                                        }}
                                    >SKIP</Button>
                                </div>
                            })}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <h3>Already Imported</h3>
                            <div style={{marginBottom:10}}>
                                {props.alreadyImportedList.length} contact(s)
                            </div>
                            {props.alreadyImportedList.map((ail,i) => {
                                return <div key={`${i}-ai`} className="boxed">
                                    <h4>#{i+1} {ail.chosenType}</h4>
                                    <ContactBox 
                                        obj={ail.chosen}
                                        boxType='already-imported'
                                    />
                                    <Button color="rose"
                                        onClick={() => {
                                            console.log('ail', ail);
                                            setOpenedExistingContact(ail.chosen);
                                        }}
                                    >EDIT</Button>
                                </div>
                            })}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <h3>Skipped</h3>
                            <div style={{marginBottom:10}}>
                                {skipped.length} contact(s)
                            </div>
                            {skipped.map((sk,i) => {
                                return <div key={`${i}-ax`} className="boxed">
                                    <h4>#{i+1}</h4>
                                    <ContactBox 
                                        obj={sk}
                                    />
                                    <Button color="rose"
                                        onClick={() => {
                                            setSkipped(skipped.filter(cti => {
                                                return cti.id !== sk.id;
                                            }));
                                            setContactsToImport(props.contactsToImport.concat([sk]));
                                            const newQlist = qList.concat([sk]);
                                            setQList(newQlist);
                                            if (newQlist[qNum]) {
                                                setCurrentContact(newQlist[qNum]);
                                            } else {
                                                setCurrentContact(newQlist[0]);
                                            }
                                        }}
                                    >RETURN TO IMPORT QUEUE</Button>
                                </div>
                            })}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="text-center">
                                <Button color="danger"
                                    onClick={() => {
                                        props.onClose();
                                    }}
                                >CLOSE</Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Dialog>

            {openedContactModal && <CustomDialogWithContact
                open={openedContactModal}
                close={() => {
                    setOpenedContactModal(false)
                }}
                choose={(chosen) => {
                    chooseContact(chosen, 'Merged with existing contact');
                }}
                create_and_choose={(chosen) => {
                    chooseContact(chosen, 'Created new contact');
                }}
                label='Search Contacts'
                prefill={{
                    first_name: currentContact.first_name,
                    last_name: currentContact.last_name,
                    main_phone: currentContact.primary_phone,
                    email: currentContact.email,
                    company_name: currentContact.company
                }}
                overwrite_existing={true}
                title={`Import Contact ${chk(currentContact.first_name)} ${chk(currentContact.last_name)}`}
                skipComponent={<Button
                    color="rose"
                    style={{marginRight:20,marginTop:20}}
                    onClick={() => {
                        skip(currentContact);
                    }}
                >SKIP</Button>}
                dontShowFilters={true}
                showCompanyAssigned={true} // tells Company Form to auto open if has company_name
            />}

            {openedExistingContact && <PropertiesEditContact
						open={openedExistingContact ? true : false}
                        autoEdit={true}
						close={() => {
							setOpenedExistingContact(null);
						}}
						contactID={openedExistingContact.id}
						choose={(chosen) => {
							// update existing alread imported
                            console.log('chosen', chosen);
                            setOpenedExistingContact(null);
						}}
                        showCompanyAssigned={true} // tells Company Form to auto open if has company_name
					/>}

            {notification && <Notification 
                open={notification ? true : false}
                close={() => { setNotification('') }}
                autoClose={2000}
                message={notification}
            />}

        </div>
    );
}

export default ContactsImporter;

