import { takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';

import { loginUserSaga } from './auth';

import {
	createFileUploadSaga,
	getFileUploadSaga,
	getFilesDataSaga
} from './files';

import {
	getPmaEntriesSaga,
	getPmaEntrySaga,
	createPmaEntrySaga,
	updatePmaEntrySaga,
	getPmaPdfSaga,
	getPmaHistorySaga,
	deletePmaEntrySaga,
	getPmaApproversSaga,
	getPmaSigningUrlSaga,
	getPmaHistoryPdfSaga,
	getPmaTemplatesSaga,
	getPmaTemplateSaga,
	createPmaTemplateSaga,
	updatePmaTemplateSaga,
	deletePmaTemplateSaga,
	resendPmaApproverNotificationSaga,
	getPmaFileSaga,
	voidPmaEntrySaga,
} from './pma';

import {
	getListingEntrySaga
} from './listing';

import {
	getContactsSaga,
	getContactSaga,
	createContactSaga,
	deleteContactSaga,
	getAddressesSaga,
	updateContactSaga,
	getContactOptionsSaga,
	getPartnersSaga,
	getContactNamesSaga,
	getContactSharesSaga,
	getContactUsernamesSaga,
	createContactShareSaga,
	deleteContactShareSaga,
	createCrmFileSaga,
	getCrmFileSaga,
	getCrmFileListSaga,
	deleteCrmFileSaga,
	addPropertyToContactSaga,
	removePropertyToContactSaga,
	getPropertyToContactSaga,
	getContactDataSaga,
	getContactChangesSaga,
	saveContactChangesSaga,
	getContactEntitiesSaga,
	createContactEntitySaga,
	deleteContactEntitySaga,
	getUpdatedContactEntitySaga,
	getContactDbasSaga,
	createContactDbaSaga,
	updateContactDbaSaga,
	deleteContactDbaSaga,
	getContactCompaniesSaga,
	getCrmDataSaga,
	updateCrmDataSaga
} from './crm_contacts';

import {
	getCompaniesSaga,
	getCompanySaga,
	createCompanySaga,
	updateCompanySaga,
	deleteCompanySaga,
	getCompanyContactsSaga,
	createCompanyContactsSaga,
	deleteCompanyContactsSaga,
	updateCompanyContactsSaga,
	getCompanyUsernamesSaga,
	getCompanySharesSaga,
	createCompanyShareSaga,
	deleteCompanyShareSaga,
	getCompanyPropertiesSaga,
	deleteCompanyPropertiesSaga,
	createCompanyPropertiesSaga,
	getCompanyChangesSaga,
	saveCompanyChangesSaga,
	getCompanyContactsDetailSaga,
	getCompanyEntitiesSaga,
	createCompanyEntitySaga,
	deleteCompanyEntitySaga,
	getUpdatedCompanyEntitySaga,
	getCompanyDbasSaga,
	createCompanyDbaSaga,
	updateCompanyDbaSaga,
	deleteCompanyDbaSaga,
	getCompanyCompaniesSaga,
	createCompanyCompanySaga,
	updateCompanyCompanySaga,
	deleteCompanyCompanySaga
} from './crm_companies';

import {
	getPropertiesSaga,
	getPropertySaga,
	createPropertySaga,
	updatePropertySaga,
	deletePropertySaga,
	getPropertySharesSaga,
	createPropertyShareSaga,
	deletePropertyShareSaga,
	getPropertyContactsSaga,
	getLeasesSaga,
	getLeaseSaga,
	createLeaseSaga,
	updateLeaseSaga,
	deleteLeaseSaga,
	getPropertyNamesSaga,
	getListingsSaga,
	getListingSaga,
	createListingSaga,
	updateListingSaga,
	deleteListingSaga,
	getMeetingsSaga,
	getMeetingSaga,
	createMeetingSaga,
	updateMeetingSaga,
	deleteMeetingSaga,
	getOffersSaga,
	getOfferSaga,
	createOfferSaga,
	updateOfferSaga,
	deleteOfferSaga,
	getSubPropertiesSaga,
	checkPropertyExistingAddressSaga,
	getOptionsSaga,
	getPropertyChangesSaga,
	savePropertyChangesSaga,
	importPropertiesSaga,
	getPropertyOwnersContactsSaga,
	importCostarPropertiesSaga,
	createSendToMarketingSaga,
	getPropertyDocumentsSaga,
	checkIlliNumberForPropertySaga,
	getPropertyFilesSaga
} from './crm_properties';

import {
	createTemplateSaga,
	getTemplatesSaga,
	getTemplateSaga,
	deleteTemplateSaga,
	updateErtlTemplateSaga
} from './ertl_templates';

import {
	createEntrySaga,
	getEntriesSaga,
	getEntriesByStatusSaga,
	getEntryPdfSaga,
	updateEntryStatusSaga,
	deleteErtlEntrySaga,
	getErtlHistorySaga,
	getErtlPdfSaga,
	getErtlHistoryEntrySaga,
	getErtlUrlSaga,
	getClientErtlSaga,
	updateClientErtlEntrySaga,
	sendErtlReviewLinkSaga,
	getErtlEntrySaga,
	getErtlApproversSaga,
	updateErtlSaga
} from './ertl_entries';

import {
	getAppointmentsSaga,
	getAppointmentSaga,
	createAppointmentSaga,
	deleteAppointmentSaga,
	updateAppointmentSaga,
} from './crm_appointments';
import {
	getShowingsSaga,
	getShowingSaga,
	createShowingSaga,
	deleteShowingSaga,
	updateShowingSaga,
} from './crm_showings';

import {
	getWorkspaceUsersSaga,
	syncUsersSaga,
	createWorkspaceUserSaga,
	deleteWorkspaceUserSaga,
	updateWorkspaceUserSaga,
	checkUsernameSaga,
	getWorkspaceGroupsSaga,
	createWorkspaceGroupSaga,
	deleteWorkspaceGroupSaga,
	updateWorkspaceGroupSaga,
	getWorkspaceGroupMembersSaga,
	createWorkspaceGroupMemberSaga,
	deleteWorkspaceGroupMemberSaga,
	createUserFromWorkspaceSaga
} from './user';

import {
	getUsersSaga,
	getUsersFilteredSaga,
	getUserSaga,
	saveUserPermsSaga,
	bulkAssignSaga,
	getUserByNameSaga,
	maintenanceModeSaga,
	checkMaintenanceModeSaga,
	notifyMailSaga,
} from './userperms';

import {
	readSettingsSaga,
	updateSettingsSaga,
	tppMaintenanceSaga,
	tppMaintenanceCheckSaga,
} from './settings';

import {
	getOnboardingsSaga,
	getObPdfSaga,
	createNteEntrySaga,
	updateOnboardingEntrySaga,
	getObWorkspaceUserSaga,
} from './ob';

import {
	getRsheetsSaga,
	getRsheetSaga,
	createRsheetSaga,
	updateRsheetSaga,
	createRsheetPdfSaga,
	deleteRsheetSaga,
	createRsheetFilesSaga,
	getRsheetFilesSaga,
	getRsTypesSaga,
	getNextRsIlliNumberSaga,
	checkRsIlliNumberSaga,
	createRsheetRevisionSaga,
	getRsHistoriesSaga,
	createRsHistorySaga,
	updateRsHistorySaga,
	deleteRsHistorySaga,
	getRsInternalReviewersSaga,
	createRsInternalReviewerSaga,
	updateRsInternalReviewerSaga,
	deleteRsInternalReviewerSaga
} from './rs';

import { createDocusignSaga } from './docusign';

import {
	getListingEntriesSaga,
	createListingEntrySaga,
	updateListingEntrySaga,
	deleteListingEntrySaga,
	getListingPdfSaga,
	getListingHistorySaga,
} from './listing';
import { 
	createInvoiceEntrySaga,  
	deleteInvoiceEntrySaga, 
	getInvoiceEntriesSaga, 
	getInvoicePdfSaga, 
	resendInvoiceEmailSaga, 
	updateInvoiceEntrySaga, 
	voidInvoicesSaga,
	getInvoicePaymentsSaga,
	createInvoicePaymentSaga,
	updateInvoicePaymentSaga,
} from './invoice';

import {
	getDialpadContactsSaga,
	getGoogleContactsSaga,
	getLogHistorySaga,
	importDialpadContactsSaga,
} from './contact_import';

import {
	getCommissionSplitsSaga,
	createCommissionSplitSaga,
	deleteCommissionSplitSaga,
	updateCommissionSplitSaga,
	getCommissionSplitsPropertyExistenceSaga,
	createCommissionSplitsHistorySaga,
	getCommissionSplitsHistorySaga,
	getCommissionSplitsReviewersSaga,
	createCommissionSplitsPdfSaga,
	getCommissionSplitsApproversSaga,
	getCsSigningUrlSaga,
	getCommissionSplitSaga
} from './commission_splits';

import {
	getTrEntriesSaga,
	getTrEntrySaga,
	createTrEntrySaga,
	updateTrEntrySaga,
	deleteTrEntrySaga,
	getTrApproversSaga,
	createTrPdfSaga,
	createTrHistoryPdfSaga,
	getTrHistorySaga,
	createTrHistorySaga,
	getTrSigningUrlSaga
} from './tr_entries';

import {
	getTrTemplatesSaga,
	createTrTemplateSaga,
	updateTrTemplateSaga,
	deleteTrTemplateSaga
} from './tr_templates';

import {
	getGciEntriesSaga,
	createGciEntrySaga,
	deleteGciEntrySaga,
	updateGciEntrySaga,
	getEarnedCommissionsSaga,
	createEarnedCommissionsSaga
} from './gci';

export function* watchAD() {
	yield takeLatest(actionTypes.AUTH_REQUEST, loginUserSaga);

	// CRM
	yield takeLatest(actionTypes.CREATE_CRM_FILE, createCrmFileSaga);
	yield takeLatest(actionTypes.GET_CRM_FILE, getCrmFileSaga);
	yield takeLatest(actionTypes.GET_CRM_FILE_LIST, getCrmFileListSaga);
	yield takeLatest(actionTypes.DELETE_CRM_FILE, deleteCrmFileSaga);

	// CONTACTS
	yield takeLatest(actionTypes.GET_CRM_DATA, getCrmDataSaga);
	yield takeLatest(actionTypes.UPDATE_CRM_DATA, updateCrmDataSaga);
	yield takeLatest(actionTypes.GET_CONTACTS, getContactsSaga);
	yield takeLatest(actionTypes.GET_CONTACT, getContactSaga);
	yield takeLatest(actionTypes.CREATE_CONTACT, createContactSaga);
	yield takeLatest(actionTypes.DELETE_CONTACT, deleteContactSaga);
	yield takeLatest(actionTypes.UPDATE_CONTACT, updateContactSaga);
	yield takeLatest(actionTypes.GET_ADDRESSES, getAddressesSaga);
	yield takeLatest(actionTypes.GET_CONTACT_OPTIONS, getContactOptionsSaga);
	yield takeLatest(actionTypes.GET_PARTNERS, getPartnersSaga);
	yield takeLatest(actionTypes.GET_CONTACT_SHARES, getContactSharesSaga);
	yield takeLatest(
		actionTypes.GET_CONTACT_USERNAMES,
		getContactUsernamesSaga
	);
	yield takeLatest(actionTypes.CREATE_CONTACT_SHARE, createContactShareSaga);
	yield takeLatest(actionTypes.DELETE_CONTACT_SHARE, deleteContactShareSaga);
	yield takeLatest(actionTypes.GET_CONTACT_NAMES, getContactNamesSaga);
	yield takeLatest(actionTypes.ADD_PROPERTY_TO_CONTACT, addPropertyToContactSaga);
	yield takeLatest(actionTypes.REMOVE_PROPERTY_TO_CONTACT, removePropertyToContactSaga);
	yield takeLatest(actionTypes.GET_PROPERTY_TO_CONTACT, getPropertyToContactSaga);
	yield takeLatest(actionTypes.GET_CONTACT_DATA, getContactDataSaga);
	yield takeLatest(actionTypes.GET_CONTACT_CHANGES, getContactChangesSaga);
	yield takeLatest(actionTypes.SAVE_CONTACT_CHANGES, saveContactChangesSaga);
	yield takeLatest(actionTypes.GET_CONTACT_ENTITIES, getContactEntitiesSaga);
	yield takeLatest(actionTypes.CREATE_CONTACT_ENTITY, createContactEntitySaga);
	yield takeLatest(actionTypes.DELETE_CONTACT_ENTITY, deleteContactEntitySaga);
	yield takeLatest(actionTypes.GET_UPDATED_CONTACT_ENTITY, getUpdatedContactEntitySaga);
	yield takeLatest(actionTypes.GET_CONTACT_DBAS, getContactDbasSaga);
	yield takeLatest(actionTypes.CREATE_CONTACT_DBA, createContactDbaSaga);
	yield takeLatest(actionTypes.UPDATE_CONTACT_DBA, updateContactDbaSaga);
	yield takeLatest(actionTypes.DELETE_CONTACT_DBA, deleteContactDbaSaga);
	yield takeLatest(actionTypes.GET_CONTACT_COMPANIES, getContactCompaniesSaga);

	// COMPANIES
	yield takeLatest(actionTypes.GET_COMPANIES, getCompaniesSaga);
	yield takeLatest(actionTypes.GET_COMPANY, getCompanySaga);
	yield takeLatest(actionTypes.CREATE_COMPANY, createCompanySaga);
	yield takeLatest(actionTypes.UPDATE_COMPANY, updateCompanySaga);
	yield takeLatest(actionTypes.DELETE_COMPANY, deleteCompanySaga);
	yield takeLatest(actionTypes.GET_COMPANY_CONTACTS, getCompanyContactsSaga);
	yield takeLatest(actionTypes.CREATE_COMPANY_CONTACTS, createCompanyContactsSaga);
	yield takeLatest(
		actionTypes.DELETE_COMPANY_CONTACTS,
		deleteCompanyContactsSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_COMPANY_CONTACTS,
		updateCompanyContactsSaga
	);
	yield takeLatest(
		actionTypes.GET_COMPANY_USERNAMES,
		getCompanyUsernamesSaga
	);
	yield takeLatest(actionTypes.GET_COMPANY_SHARES, getCompanySharesSaga);
	yield takeLatest(actionTypes.CREATE_COMPANY_SHARE, createCompanyShareSaga);
	yield takeLatest(actionTypes.DELETE_COMPANY_SHARE, deleteCompanyShareSaga);
	yield takeLatest(
		actionTypes.GET_COMPANY_PROPERTIES,
		getCompanyPropertiesSaga
	);
	yield takeLatest(
		actionTypes.DELETE_COMPANY_PROPERTIES,
		deleteCompanyPropertiesSaga
	);
	yield takeLatest(
		actionTypes.CREATE_COMPANY_PROPERTIES,
		createCompanyPropertiesSaga
	);
	yield takeLatest(actionTypes.GET_COMPANY_CHANGES, getCompanyChangesSaga);
	yield takeLatest(actionTypes.SAVE_COMPANY_CHANGES, saveCompanyChangesSaga);
	yield takeLatest(actionTypes.GET_COMPANY_CONTACTS_DETAIL, getCompanyContactsDetailSaga);

	yield takeLatest(actionTypes.GET_COMPANY_ENTITIES, getCompanyEntitiesSaga);
	yield takeLatest(actionTypes.CREATE_COMPANY_ENTITY, createCompanyEntitySaga);
	yield takeLatest(actionTypes.DELETE_COMPANY_ENTITY, deleteCompanyEntitySaga);

	yield takeLatest(actionTypes.GET_UPDATED_COMPANY_ENTITY, getUpdatedCompanyEntitySaga);

	yield takeLatest(actionTypes.GET_COMPANY_DBAS, getCompanyDbasSaga);
	yield takeLatest(actionTypes.CREATE_COMPANY_DBA, createCompanyDbaSaga);
	yield takeLatest(actionTypes.UPDATE_COMPANY_DBA, updateCompanyDbaSaga);
	yield takeLatest(actionTypes.DELETE_COMPANY_DBA, deleteCompanyDbaSaga);

	yield takeLatest(actionTypes.GET_COMPANY_COMPANIES, getCompanyCompaniesSaga);
	yield takeLatest(actionTypes.CREATE_COMPANY_COMPANY, createCompanyCompanySaga);
	yield takeLatest(actionTypes.UPDATE_COMPANY_COMPANY, updateCompanyCompanySaga);
	yield takeLatest(actionTypes.DELETE_COMPANY_COMPANY, deleteCompanyCompanySaga);

	// PROPERTIES
	yield takeLatest(actionTypes.GET_PROPERTIES, getPropertiesSaga);
	yield takeLatest(actionTypes.GET_PROPERTY, getPropertySaga);
	yield takeLatest(
		actionTypes.GET_PROPERTY_CONTACTS,
		getPropertyContactsSaga
	);
	yield takeLatest(actionTypes.GET_PROPERTY_NAMES, getPropertyNamesSaga);
	yield takeLatest(actionTypes.CREATE_PROPERTY, createPropertySaga);
	yield takeLatest(actionTypes.DELETE_PROPERTY, deletePropertySaga);
	yield takeLatest(actionTypes.UPDATE_PROPERTY, updatePropertySaga);
	yield takeLatest(actionTypes.GET_SUB_PROPERTIES, getSubPropertiesSaga);
	yield takeLatest(
		actionTypes.CHECK_PROPERTY_EXISTING_ADDRESS,
		checkPropertyExistingAddressSaga
	);
	yield takeLatest(actionTypes.GET_OPTIONS, getOptionsSaga);
	yield takeLatest(actionTypes.GET_PROPERTY_CHANGES, getPropertyChangesSaga);
	yield takeLatest(actionTypes.SAVE_PROPERTY_CHANGES, savePropertyChangesSaga);
	yield takeLatest(actionTypes.CREATE_SEND_TO_MARKETING, createSendToMarketingSaga);
	yield takeLatest(actionTypes.GET_PROPERTY_DOCUMENTS, getPropertyDocumentsSaga);
	yield takeLatest(actionTypes.CHECK_ILLI_NUMBER_FOR_PROPERTY, checkIlliNumberForPropertySaga);
	yield takeLatest(actionTypes.GET_PROPERTY_FILES, getPropertyFilesSaga);

	// LEASES
	yield takeLatest(actionTypes.GET_LEASES, getLeasesSaga);
	yield takeLatest(actionTypes.GET_LEASE, getLeaseSaga);
	yield takeLatest(actionTypes.CREATE_LEASE, createLeaseSaga);
	yield takeLatest(actionTypes.UPDATE_LEASE, updateLeaseSaga);
	yield takeLatest(actionTypes.DELETE_LEASE, deleteLeaseSaga);

	// LISTINGS
	yield takeLatest(actionTypes.GET_LISTINGS, getListingsSaga);
	yield takeLatest(actionTypes.GET_LISTING, getListingSaga);
	yield takeLatest(actionTypes.CREATE_LISTING, createListingSaga);
	yield takeLatest(actionTypes.UPDATE_LISTING, updateListingSaga);
	yield takeLatest(actionTypes.DELETE_LISTING, deleteListingSaga);

	// MEETINGS
	yield takeLatest(actionTypes.GET_MEETINGS, getMeetingsSaga);
	yield takeLatest(actionTypes.GET_MEETING, getMeetingSaga);
	yield takeLatest(actionTypes.CREATE_MEETING, createMeetingSaga);
	yield takeLatest(actionTypes.UPDATE_MEETING, updateMeetingSaga);
	yield takeLatest(actionTypes.DELETE_MEETING, deleteMeetingSaga);

	// OFFERS
	yield takeLatest(actionTypes.GET_OFFERS, getOffersSaga);
	yield takeLatest(actionTypes.GET_OFFER, getOfferSaga);
	yield takeLatest(actionTypes.CREATE_OFFER, createOfferSaga);
	yield takeLatest(actionTypes.UPDATE_OFFER, updateOfferSaga);
	yield takeLatest(actionTypes.DELETE_OFFER, deleteOfferSaga);

	// CALENDAR
	yield takeLatest(
		actionTypes.GET_CALENDAR_APPOINTMENTS,
		getAppointmentsSaga
	);
	yield takeLatest(actionTypes.GET_CALENDAR_APPOINTMENT, getAppointmentSaga);
	yield takeLatest(
		actionTypes.CREATE_CALENDAR_APPOINTMENT,
		createAppointmentSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_CALENDAR_APPOINTMENT,
		updateAppointmentSaga
	);
	yield takeLatest(
		actionTypes.DELETE_CALENDAR_APPOINTMENT,
		deleteAppointmentSaga
	);

	// PROPERTY SHARES
	yield takeLatest(actionTypes.GET_PROPERTY_SHARES, getPropertySharesSaga);
	yield takeLatest(
		actionTypes.CREATE_PROPERTY_SHARE,
		createPropertyShareSaga
	);
	yield takeLatest(
		actionTypes.DELETE_PROPERTY_SHARE,
		deletePropertyShareSaga
	);

	// SHOWING
	yield takeLatest(actionTypes.GET_SHOWINGS, getShowingsSaga);
	yield takeLatest(actionTypes.GET_SHOWING, getShowingSaga);
	yield takeLatest(actionTypes.CREATE_SHOWING, createShowingSaga);
	yield takeLatest(actionTypes.DELETE_SHOWING, deleteShowingSaga);
	yield takeLatest(actionTypes.UPDATE_SHOWING, updateShowingSaga);

	// USER ADMIN
	yield takeLatest(actionTypes.GET_WORKSPACE_USERS, getWorkspaceUsersSaga);
	yield takeLatest(actionTypes.SYNC_USERS, syncUsersSaga);
	yield takeLatest(
		actionTypes.CREATE_WORKSPACE_USER,
		createWorkspaceUserSaga
	);
	yield takeLatest(
		actionTypes.DELETE_WORKSPACE_USER,
		deleteWorkspaceUserSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_WORKSPACE_USER,
		updateWorkspaceUserSaga
	);
	yield takeLatest(actionTypes.CHECK_USERNAME, checkUsernameSaga);
	yield takeLatest(actionTypes.GET_WORKSPACE_GROUPS, getWorkspaceGroupsSaga);
	yield takeLatest(
		actionTypes.CREATE_WORKSPACE_GROUP,
		createWorkspaceGroupSaga
	);
	yield takeLatest(
		actionTypes.DELETE_WORKSPACE_GROUP,
		deleteWorkspaceGroupSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_WORKSPACE_GROUP,
		updateWorkspaceGroupSaga
	);
	yield takeLatest(
		actionTypes.GET_WORKSPACE_GROUP_MEMBERS,
		getWorkspaceGroupMembersSaga
	);
	yield takeLatest(
		actionTypes.CREATE_WORKSPACE_GROUP_MEMBER,
		createWorkspaceGroupMemberSaga
	);
	yield takeLatest(
		actionTypes.DELETE_WORKSPACE_GROUP_MEMBER,
		deleteWorkspaceGroupMemberSaga
	);
	yield takeLatest(actionTypes.CREATE_USER_FROM_WORKSPACE, createUserFromWorkspaceSaga);

	// USER PERMS
	yield takeLatest(actionTypes.GET_USERS, getUsersSaga);
	yield takeLatest(actionTypes.GET_USERS_FILTERED, getUsersFilteredSaga);
	yield takeLatest(actionTypes.GET_USER, getUserSaga);
	yield takeLatest(actionTypes.GET_USER_BY_NAME, getUserByNameSaga);
	yield takeLatest(actionTypes.SAVE_USER_PERMS, saveUserPermsSaga);
	yield takeLatest(actionTypes.BULK_ASSIGN, bulkAssignSaga);
	yield takeLatest(actionTypes.MAINTENANCE_MODE, maintenanceModeSaga);
	yield takeLatest(
		actionTypes.CHECK_MAINTENANCE_MODE,
		checkMaintenanceModeSaga
	);
	yield takeLatest(actionTypes.NOTIFY_MAIL, notifyMailSaga);

	// SETTINGS
	yield takeLatest(actionTypes.READ_SETTINGS, readSettingsSaga);
	yield takeLatest(actionTypes.UPDATE_SETTINGS, updateSettingsSaga);
	yield takeLatest(actionTypes.TPP_MAINTENANCE, tppMaintenanceSaga);
	yield takeLatest(
		actionTypes.TPP_MAINTENANCE_CHECK,
		tppMaintenanceCheckSaga
	);

	// PMA
	yield takeLatest(actionTypes.GET_PMA_ENTRIES, getPmaEntriesSaga);
	yield takeLatest(actionTypes.GET_PMA_ENTRY, getPmaEntrySaga);
	yield takeLatest(actionTypes.CREATE_PMA_ENTRY, createPmaEntrySaga);
	yield takeLatest(actionTypes.UPDATE_PMA_ENTRY, updatePmaEntrySaga);
	yield takeLatest(actionTypes.GET_PMA_PDF, getPmaPdfSaga);
	yield takeLatest(actionTypes.GET_PMA_HISTORY, getPmaHistorySaga);
	yield takeLatest(actionTypes.DELETE_PMA_ENTRY, deletePmaEntrySaga);
	yield takeLatest(actionTypes.GET_PMA_APPROVERS, getPmaApproversSaga);
	yield takeLatest(actionTypes.GET_PMA_SIGNING_URL, getPmaSigningUrlSaga);
	yield takeLatest(actionTypes.GET_PMA_HISTORY_PDF, getPmaHistoryPdfSaga);
	yield takeLatest(actionTypes.GET_PMA_TEMPLATES, getPmaTemplatesSaga);
	yield takeLatest(actionTypes.GET_PMA_TEMPLATE, getPmaTemplateSaga);
	yield takeLatest(actionTypes.CREATE_PMA_TEMPLATE, createPmaTemplateSaga);
	yield takeLatest(actionTypes.UPDATE_PMA_TEMPLATE, updatePmaTemplateSaga);
	yield takeLatest(actionTypes.DELETE_PMA_TEMPLATE, deletePmaTemplateSaga);
	yield takeLatest(actionTypes.RESEND_PMA_APPROVER_NOTIFICATION, resendPmaApproverNotificationSaga);
	yield takeLatest(actionTypes.GET_PMA_FILE, getPmaFileSaga);
	yield takeLatest(actionTypes.VOID_PMA_ENTRY, voidPmaEntrySaga);

	// DOCUSIGN
	yield takeLatest(actionTypes.CREATE_DOCUSIGN, createDocusignSaga);

	// ERTL
	yield takeLatest(actionTypes.GET_TEMPLATES, getTemplatesSaga);
	yield takeLatest(actionTypes.GET_TEMPLATE, getTemplateSaga);
	yield takeLatest(actionTypes.CREATE_TEMPLATE, createTemplateSaga);
	yield takeLatest(actionTypes.DELETE_TEMPLATE, deleteTemplateSaga);
	yield takeLatest(actionTypes.GET_ENTRIES, getEntriesSaga);
	yield takeLatest(actionTypes.GET_ENTRIES_BY_STATUS, getEntriesByStatusSaga);
	yield takeLatest(actionTypes.CREATE_ENTRY, createEntrySaga);
	yield takeLatest(actionTypes.UPDATE_ENTRY_STATUS, updateEntryStatusSaga);
	yield takeLatest(actionTypes.GET_ENTRY_PDF, getEntryPdfSaga);
	yield takeLatest(actionTypes.DELETE_ERTL_ENTRY, deleteErtlEntrySaga);
	yield takeLatest(actionTypes.GET_ERTL_HISTORY, getErtlHistorySaga);
	yield takeLatest(actionTypes.GET_ERTL_PDF, getErtlPdfSaga);
	yield takeLatest(
		actionTypes.GET_ERTL_HISTORY_ENTRY,
		getErtlHistoryEntrySaga
	);
	yield takeLatest(actionTypes.GET_ERTL_URL, getErtlUrlSaga);
	yield takeLatest(actionTypes.UPDATE_ERTL_TEMPLATE, updateErtlTemplateSaga);
	yield takeLatest(actionTypes.GET_ERTL_ENTRY, getErtlEntrySaga);
	yield takeLatest(actionTypes.GET_ERTL_APPROVERS, getErtlApproversSaga);
	yield takeLatest(actionTypes.UPDATE_ERTL, updateErtlSaga);

	// OB
	yield takeLatest(actionTypes.GET_ONBOARDINGS, getOnboardingsSaga);
	yield takeLatest(actionTypes.GET_OB_PDF, getObPdfSaga);
	yield takeLatest(actionTypes.CREATE_NTE_ENTRY, createNteEntrySaga);
	yield takeLatest(
		actionTypes.UPDATE_ONBOARDING_ENTRY,
		updateOnboardingEntrySaga
	);
	yield takeLatest(actionTypes.GET_OB_WORKSPACE_USER, getObWorkspaceUserSaga);
	yield takeLatest(actionTypes.GET_CLIENT_ERTL, getClientErtlSaga);
	yield takeLatest(actionTypes.UPDATE_CLIENT_ERTL_ENTRY, updateClientErtlEntrySaga);
	yield takeLatest(actionTypes.SEND_ERTL_REVIEW_LINK, sendErtlReviewLinkSaga);

	// RSHEETS
	yield takeLatest(actionTypes.GET_RSHEETS, getRsheetsSaga);
	yield takeLatest(actionTypes.GET_RSHEET, getRsheetSaga);
	yield takeLatest(actionTypes.CREATE_RSHEET, createRsheetSaga);
	yield takeLatest(actionTypes.UPDATE_RSHEET, updateRsheetSaga);
	yield takeLatest(actionTypes.DELETE_RSHEET, deleteRsheetSaga);
	yield takeLatest(actionTypes.CREATE_RSHEET_PDF, createRsheetPdfSaga);
	yield takeLatest(actionTypes.CREATE_RSHEET_FILES, createRsheetFilesSaga);
	yield takeLatest(actionTypes.GET_RSHEET_FILES, getRsheetFilesSaga);
	yield takeLatest(actionTypes.GET_RS_TYPES, getRsTypesSaga);
	yield takeLatest(actionTypes.GET_NEXT_RS_ILLI_NUMBER, getNextRsIlliNumberSaga);
	yield takeLatest(actionTypes.CHECK_RS_ILLI_NUMBER, checkRsIlliNumberSaga);
	yield takeLatest(actionTypes.CREATE_RSHEET_REVISION, createRsheetRevisionSaga);
	yield takeLatest(actionTypes.GET_RS_HISTORIES, getRsHistoriesSaga);
	yield takeLatest(actionTypes.CREATE_RS_HISTORY, createRsHistorySaga);
	yield takeLatest(actionTypes.UPDATE_RS_HISTORY, updateRsHistorySaga);
	yield takeLatest(actionTypes.DELETE_RS_HISTORY, deleteRsHistorySaga);
	yield takeLatest(actionTypes.GET_RS_INTERNAL_REVIEWERS, getRsInternalReviewersSaga);
	yield takeLatest(actionTypes.CREATE_RS_INTERNAL_REVIEWER, createRsInternalReviewerSaga);
	yield takeLatest(actionTypes.UPDATE_RS_INTERNAL_REVIEWER, updateRsInternalReviewerSaga);
	yield takeLatest(actionTypes.DELETE_RS_INTERNAL_REVIEWER, deleteRsInternalReviewerSaga);

	// LISTING SUBMISSIONS
	yield takeLatest(actionTypes.GET_LISTING_ENTRIES, getListingEntriesSaga);
	yield takeLatest(actionTypes.GET_LISTING_ENTRY, getListingEntrySaga);
	yield takeLatest(actionTypes.CREATE_LISTING_ENTRY, createListingEntrySaga);
	yield takeLatest(actionTypes.UPDATE_LISTING_ENTRY, updateListingEntrySaga);
	yield takeLatest(actionTypes.DELETE_LISTING_ENTRY, deleteListingEntrySaga);
	yield takeLatest(actionTypes.GET_LISTING_PDF, getListingPdfSaga);
	yield takeLatest(actionTypes.GET_LISTING_HISTORY, getListingHistorySaga);

	// INVOICES
	yield takeLatest(actionTypes.GET_INVOICE_ENTRIES, getInvoiceEntriesSaga);
	yield takeLatest(actionTypes.CREATE_INVOICE_ENTRY, createInvoiceEntrySaga);
	yield takeLatest(actionTypes.UPDATE_INVOICE_ENTRY, updateInvoiceEntrySaga);
	yield takeLatest(actionTypes.DELETE_INVOICE_ENTRY, deleteInvoiceEntrySaga);
	yield takeLatest(actionTypes.GET_INVOICE_PDF, getInvoicePdfSaga);
	yield takeLatest(actionTypes.RESEND_INVOICE_EMAIL, resendInvoiceEmailSaga);
	yield takeLatest(actionTypes.VOID_INVOICES, voidInvoicesSaga);
	yield takeLatest(actionTypes.GET_INVOICE_PAYMENTS, getInvoicePaymentsSaga);
	yield takeLatest(actionTypes.CREATE_INVOICE_PAYMENT, createInvoicePaymentSaga);
	yield takeLatest(actionTypes.UPDATE_INVOICE_PAYMENT, updateInvoicePaymentSaga);

	// GET DIALPAD EVENTS
	yield takeLatest(actionTypes.GET_DIALPAD_CONTACTS, getDialpadContactsSaga);
	yield takeLatest(actionTypes.IMPORT_DIALPAD_CONTACTS, importDialpadContactsSaga);
	yield takeLatest(actionTypes.GET_LOG_HISTORY, getLogHistorySaga);

	// GOOGLE CONTACTS
	yield takeLatest(actionTypes.GET_GOOGLE_CONTACTS, getGoogleContactsSaga);
	
	// IMPORT PROPERTIES
	yield takeLatest(actionTypes.IMPORT_PROPERTIES, importPropertiesSaga);
	
	// IMPORT COSTAR PROPERTIES
	yield takeLatest(actionTypes.IMPORT_COSTAR_PROPERTIES, importCostarPropertiesSaga);

	// GET PROPERTY OWNERS CONTACTS
	yield takeLatest(actionTypes.GET_PROPERTY_OWNERS_CONTACTS, getPropertyOwnersContactsSaga);

	// FILES
	yield takeLatest(actionTypes.CREATE_FILE_UPLOAD, createFileUploadSaga);
	yield takeLatest(actionTypes.GET_FILE_UPLOAD, getFileUploadSaga);
	yield takeLatest(actionTypes.GET_FILES_DATA, getFilesDataSaga);

	// COMMISSION SPLITS 
	yield takeLatest(actionTypes.GET_COMMISSION_SPLITS, getCommissionSplitsSaga);
	yield takeLatest(actionTypes.CREATE_COMMISSION_SPLIT, createCommissionSplitSaga);
	yield takeLatest(actionTypes.DELETE_COMMISSION_SPLIT, deleteCommissionSplitSaga);
	yield takeLatest(actionTypes.UPDATE_COMMISSION_SPLIT, updateCommissionSplitSaga);
	yield takeLatest(actionTypes.GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE, getCommissionSplitsPropertyExistenceSaga);
	yield takeLatest(actionTypes.CREATE_COMMISSION_SPLITS_HISTORY, createCommissionSplitsHistorySaga);
	yield takeLatest(actionTypes.GET_COMMISSION_SPLITS_HISTORY, getCommissionSplitsHistorySaga);
	yield takeLatest(actionTypes.GET_COMMISSION_SPLITS_REVIEWERS, getCommissionSplitsReviewersSaga);
	yield takeLatest(actionTypes.CREATE_COMMISSION_SPLITS_PDF, createCommissionSplitsPdfSaga);
	yield takeLatest(actionTypes.GET_COMMISSION_SPLITS_APPROVERS, getCommissionSplitsApproversSaga);
	yield takeLatest(actionTypes.GET_CS_SIGNING_URL, getCsSigningUrlSaga);
	yield takeLatest(actionTypes.GET_COMMISSION_SPLIT, getCommissionSplitSaga);

	// TENANT REP ENTRIES
	yield takeLatest(actionTypes.GET_TR_ENTRIES, getTrEntriesSaga);
	yield takeLatest(actionTypes.GET_TR_ENTRY, getTrEntrySaga);
	yield takeLatest(actionTypes.CREATE_TR_ENTRY, createTrEntrySaga);
	yield takeLatest(actionTypes.UPDATE_TR_ENTRY, updateTrEntrySaga);
	yield takeLatest(actionTypes.DELETE_TR_ENTRY, deleteTrEntrySaga);
	yield takeLatest(actionTypes.CREATE_TR_PDF, createTrPdfSaga);
	yield takeLatest(actionTypes.CREATE_TR_HISTORY_PDF, createTrHistoryPdfSaga);
	yield takeLatest(actionTypes.GET_TR_HISTORY, getTrHistorySaga);
	yield takeLatest(actionTypes.GET_TR_SIGNING_URL, getTrSigningUrlSaga);

	// TENANT REP TEMPLATES 
	yield takeLatest(actionTypes.GET_TR_TEMPLATES, getTrTemplatesSaga);
	yield takeLatest(actionTypes.CREATE_TR_TEMPLATE, createTrTemplateSaga);
	yield takeLatest(actionTypes.UPDATE_TR_TEMPLATE, updateTrTemplateSaga);
	yield takeLatest(actionTypes.DELETE_TR_TEMPLATE, deleteTrTemplateSaga);
	yield takeLatest(actionTypes.GET_TR_APPROVERS, getTrApproversSaga);
	yield takeLatest(actionTypes.CREATE_TR_HISTORY, createTrHistorySaga);

	// GCI
	yield takeLatest(actionTypes.GET_GCI_ENTRIES, getGciEntriesSaga);
	yield takeLatest(actionTypes.CREATE_GCI_ENTRY, createGciEntrySaga);
	yield takeLatest(actionTypes.DELETE_GCI_ENTRY, deleteGciEntrySaga);
	yield takeLatest(actionTypes.UPDATE_GCI_ENTRY, updateGciEntrySaga);
	yield takeLatest(actionTypes.GET_EARNED_COMMISSIONS, getEarnedCommissionsSaga);
	yield takeLatest(actionTypes.CREATE_EARNED_COMMISSIONS, createEarnedCommissionsSaga);
}
