// set devStatus to indicate if the app is in live(production) or stage mode
// CONFIGURABLE OPTIONS :
// ----------------------
const is_local_server = false; // true if using localhost dev API
const devStatus = 'stage'; // local_live, local_stage, live, stage (NOTE: local means turning on Chrome Redux Tools)

// DO NOT CHANGE BELOW :
// ---------------------
export const domain = is_local_server ? // api url
        'http://localhost:1337'
    :
        devStatus.indexOf('stage') !== -1 ?
            'https://staging.connect.illicre.com'
        :
            'https://connect.illicre.com'; // live

export const workspaceDomain = devStatus.indexOf('stage') !== -1 ?
        'sap-developers.com'
    :
        'illicre.com'; // live

export const googleClientID = devStatus.indexOf('stage') !== -1 ?
        '334098894161-us9l12i3ueilufsoq852o0fhf9e0pq55.apps.googleusercontent.com'
    :
        '591927356711-3s6fjrcu32u1j8mihuq0r060rhe47rpl.apps.googleusercontent.com'; // live

export default devStatus;
